* {
    word-break:break-word
}
.wrapper {
    height:auto
}
@media (min-width:1024px) {
    .container {
        max-width:98%
    }
}
@media (min-width:1200px) {
    .container {
        max-width:1200px
    }
}
* {
    font-display:swap;
    font-family:Inter
}
a {
    text-decoration:none
}
.f_clash {
    font-display:swap;
    font-family:'Clash Display'
}
.f_inter {
    font-display:swap;
    font-family:Inter
}
:root {
    --clBlua:#3787E6!important;
    --clBlub:#87BBF9!important;
    --clBluc:#9BC3F2!important;
    --clBlud:#C7DAF2!important;
    --clBlue:#E6F1FF!important;
    --clBluf:#F7FAFF!important;
    --clBlug:#EEF0F4!important;
    --clBluh:#F6F6F6!important;
    --clBlka:#000000!important;
    --clBlkb:#818181!important;
    --clBlkc:#999999!important;
    --clBlkd:#CBCBCB!important;
    --clBlke:#EDEDED!important;
    --clWhta:#FFFFFF!important;
    --clGrna:#4CD79E!important;
    --clReda:#DD6B73!important;
    --clyloa:#EAAD04!important
}
html {
    font-size:14px
}
.fs_9 {
    font-size:.643rem!important;
    line-height:.929rem
}
.fs_10 {
    font-size:.714rem!important;
    line-height:1rem
}
.fs_11 {
    font-size:.786rem!important;
    line-height:1.071rem
}
.fs_12 {
    font-size:.857rem!important;
    line-height:1.143rem
}
.fs_13 {
    font-size:13px!important
}
.fs_14 {
    font-size:1rem!important;
    line-height:1.286rem
}
.fs_15 {
    font-size:15px!important;
    line-height:17px
}
.fs_16 {
    font-size:1.143rem!important;
    line-height:1.429rem
}
.fs_18 {
    font-size:1.286rem!important;
    line-height:1.571rem
}
.fs_20 {
    font-size:1.429rem!important;
    line-height:1.714rem
}
.fs_24 {
    font-size:1.714rem!important;
    line-height:2.143rem
}
.fs_28 {
    font-size:2rem!important;
    line-height:2.429rem
}
.fs_30 {
    font-size:2.143rem!important;
    line-height:2.429rem
}
.lh_16 {
    line-height:16px!important
}
.lh_18 {
    line-height:18px!important
}
.lh_20 {
    line-height:20px!important
}
.lh_22 {
    line-height:22px!important
}
.lh_24 {
    line-height:24px!important
}
.fsl_3 {
    font-weight:300!important
}
.fsn_4 {
    font-weight:400!important;
    font-weight:400
}
.fsm_5 {
    font-weight:500!important;
    font-weight:medium
}
.fsb_6 {
    font-weight:600!important
}
.fsb_7 {
    font-weight:700!important
}
.txt_w {
    color:var(--clWhta)!important
}
.txt_b {
    color:var(--clBlka)!important
}
.txt_p {
    color:var(--clBlua)!important
}
.txt_s {
    color:var(--clGrna)!important
}
.txt_d {
    color:var(--clReda)!important
}
.text_red { color: #F00C0C !important; }
.txt_y {
    color:var(--clyloa)!important
}
.txt_lb {
    color:var(--clBlkb)!important
}
.txt_db {
    color:var(--clBlkc)!important
}
.txt_g {
    color:#00000080!important
}
.txt_cap {
    text-transform:capitalize
}
.bg_w {
    background:var(--clWhta)!important
}
.bg_b {
    background:var(--clBlka)!important
}
.bg_p {
    background:var(--clBlua)
}
.bg_s {
    background:var(--clGrna)!important
}
.bg_d {
    background:var(--clReda)!important
}
.bg_lb {
    background:var(--clBlkb)!important
}
.bg_y {
    background:var(--clyloa)!important
}
.br_4 {
    border-radius:4px!important
}
.br_6 {
    border-radius:6px!important
}
.br_8 {
    border-radius:8px!important
}
.ope_0 {
    opacity:0
}
.ope_2 {
    opacity:20%
}
.ope_4 {
    opacity:40%
}
.ope_5 {
    opacity:50%
}
.ope_6 {
    opacity:60%
}
.ope_7 {
    opacity:70%
}
.ope_8 {
    opacity:80%
}
.b_none {
    border:none
}
.mb_24 {
    margin-bottom:24px
}
.mb_32 {
    margin-bottom:32px
}
.mt_24 {
    margin-top:24px
}
.mt_32 {
    margin-top:32px
}
.ml_24 {
    margin-left:24px
}
.b_r_0 {
    border-top-right-radius:0!important;
    border-bottom-right-radius:0!important
}
.bor_n {
    border:none
}
.boxS_0 {
    box-shadow:none!important
}
.help_pointer {
    cursor:help
}
.pointer {
    cursor:pointer
}
.txt_break {
    word-break:break-all
}
.mat-radio-group {
    border:none
}
input[type=file],input[type=file]::-webkit-file-upload-button {
    cursor:pointer
}
.mat-select-placeholder,input::placeholder {
    text-transform:capitalize
}
.input_arrow_h input[type=number]::-webkit-inner-spin-button,.input_arrow_h input[type=number]::-webkit-outer-spin-button {
    display:none;
    opacity:0
}
.card .card-header-danger .card-icon,.card .card-header-danger .card-text,.card .card-header-danger:not(.card-header-icon):not(.card-header-text),.card.bg-danger,.card.card-rotate.bg-danger .back,.card.card-rotate.bg-danger .front {
    background:linear-gradient(60deg,var(--clBlua),var(--clBlua))
}
.srch_top {
    width:100%;
    max-width:175px
}
.srch_top .mat-form-field-underline {
    display:none
}
.srch_top .mat-form-field-wrapper {
    padding-bottom:.34375em
}
.srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color:var(--clWhta);
    border-radius:4px;
    padding:.7rem 1rem .7rem;
    display:flex;
    align-items:center
}
.srch_top .mat-form-field-prefix {
    order:2
}
.srch_top .mat-icon {
    width:1.714rem;
    cursor:pointer;
    margin:0 8px 0 0
}
.srch_top .material-icons {
    color:var(--clBlub)
}
.srch_top .mat-form-field-appearance-fill .mat-form-field-infix {
    padding:.5rem 0 .5rem 0!important;
    font-size:1rem;
    line-height:.143rem;
    border-top:0
}
.srch_top .mat-form-field-infix .mat-input-element::placeholder {
    font-size:1.143rem;
    font-weight:400;
    color:var(--clBlkb)
}
.srch_top.network_src .mat-form-field .mat-form-field-infix {
    padding:0 0;
    font-size:16px;
    line-height:normal;
    border-top:0
}
.srch_top.network_src .mat-form-field-infix .mat-input-element::placeholder {
    color:var(--clBlkd)
}
.l_srch_top .mat-form-field .mat-form-field-flex {
    background-color:var(--clWhta);
    border-radius:4px;
    padding:0 8px 0 0;
    display:flex;
    align-items:center
}
.l_srch_top .mat-form-field .mat-form-field-infix {
    padding:0;
    font-size:1rem;
    line-height:.143rem;
    border-top:0
}
.vertical_tab {
    display:flex;
    flex-direction:row
}
.tab_list {
    display:flex;
    flex-basis:19%;
    flex-direction:column
}
.tabcontent {
    margin:0 0 0 2rem;
    flex-basis:84%;
    border-left:none;
    position:relative;
    height:calc(100vh - 166px)
}
.tab_list .list-group-item {
    font-size:.857rem;
    line-height:1rem;
    font-weight:500;
    background-color:inherit;
    color:var(--clBlka);
    padding:.286rem 0 .286rem .857rem;
    margin:.286rem 0;
    width:100%;
    cursor:pointer
}
.tab_list .list-group-item:hover {
    color:var(--clBlka)
}
.tab_list .list-group-item.active {
    background-color:transparent;
    margin:.286rem 0
}
.tab_list .list-group-item-action:focus,.tab_list .list-group-item-action:hover {
    background-color:#f8f9fa00
}
.tab_list .list-group-item.active {
    color:var(--clBlua);
    font-weight:600
}
.tab_list .list-group-item.active:before {
    content:"";
    width:.357rem;
    height:1.071rem;
    border-radius:6px;
    background:var(--clBlua);
    position:absolute;
    left:0;
    top:.143rem
}
.laLogo2x {
    width:34px;
    height:34px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/LA-fav.png);
    background-attachment:inherit;
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain
}
#upload_file {
    width:21px;
    height:21px;
    background:url(https://staticprod.laneaxis.com/portal/images/after-login/portal-icons.svg) -16px -15px
}
#download_file {
    width:21px;
    height:21px;
    background:url(https://staticprod.laneaxis.com/portal/images/after-login/portal-icons.svg) -35px -5px
}
.disa {
    opacity:50%;
    box-shadow:none;
    cursor:no-drop!important
}
.disa:hover {
    opacity:50%;
    box-shadow:none;
    cursor:no-drop!important
}
.btn {
    text-transform:inherit;
    box-shadow:none;
    font-display:swap;
    font-family:'Clash Display'
}
.btn:disabled {
    opacity:50%;
    box-shadow:none;
    cursor:no-drop!important
}
.disabled,.disabled:hover {
    opacity:50%;
    cursor:no-drop;
    box-shadow:none
}
.disabledbt {
    opacity:50%;
    box-shadow:none;
    cursor:no-drop!important
}
.button_primary:disabled:hover {
    cursor:no-drop;
    background:var(--clBlua);
    box-shadow:none
}
.button_success:disabled:hover {
    cursor:no-drop;
    background:var(--clGrna);
    box-shadow:none
}
.custum_button {
    font-size:16px;
    font-weight:500;
    line-height:20px;
    text-transform:inherit;
    min-width:9.429rem;
    max-width:100%;
    border-radius:30px;
    padding:10px 18px;
    box-shadow:none;
    cursor:pointer
}
.button_primary,.button_primary:active,.button_primary:focus,.button_primary:hover {
    color:var(--clWhta);
    background:var(--clBlua);
    box-shadow:none;
    box-shadow:0 5px 12px 0 #041b3533
}
.button_primary_boder,.button_primary_boder:active,.button_primary_boder:focus,.button_primary_boder:hover {
    color:var(--clBlua);
    border:1px solid var(--clBlua);
    background:0 0;
    box-shadow:none
}
.button_success,.button_success:active,.button_success:focus,.button_success:hover {
    color:var(--clWhta);
    background:var(--clGrna);
    box-shadow:none
}
.button_danger,.button_danger:active,.button_danger:focus,.button_danger:hover {
    color:var(--clWhta);
    background:var(--clReda);
    box-shadow:none
}
.button_danger_boder,.button_danger_boder:active,.button_danger_boder:focus,.button_danger_boder:hover {
    color:var(--clReda);
    background:#dd6b7300;
    border:1px solid var(--clReda);
    box-shadow:none
}
.btn_outline {
    font-size:.857rem;
    line-height:1rem;
    border-radius:4px;
    padding:.571rem 1.429rem;
    text-transform:inherit;
    min-width:8.571rem
}
.btn_outline:active,.btn_outline:focus,.btn_outline:hover {
    background:#f1f7ff;
    color:var(--clBlua);
    box-shadow:none
}
.btn_32 {
    padding:9px 18px!important;
    font-size:14px!important;
    font-weight:500!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin:0
}
.custum_input_css .mat-form-field-wrapper {
    padding:0 0 1.429rem 0
}
.custom_inpt_pb_2 .mat-form-field-wrapper {
    padding:0 0 2px 0
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding:0 0 0 .2rem;
    font-size:.714rem
}
.custum_input_css .mat-form-field-subscript-wrapper {
    margin-top:0
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-infix {
    padding:12px 0 5px 0
}
.custum_input_css .mat-form-field-infix {
    border-top:.286rem solid transparent
}
.custum_input_css .upload_dock .mat-form-field-wrapper {
    padding:0
}
.custum_input_css .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,.custum_input_css .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform:translateY(-.929rem) scale(.75)!important
}
.custum_input_css .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    background:var(--clBluh);
    width:35px;
    height:40px;
    border-radius:0 4px 4px 0
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline,.custum_input_css .select_date .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon {
    color:#d7e9ff
}
.password_icon .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,.password_icon .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    display:block;
    font-size:inherit;
    width:1.929rem;
    height:1.929rem;
    position:absolute;
    bottom:-.206rem;
    right:0!important;
    top:5px!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color:#d7e9ff
}
.custum_input_css .mat-input-element {
    color:var(--clBlka)!important;
    caret-color:var(--clBlka);
    font-size:1rem!important;
    font-weight:500;
    height:40px;
    background:var(--clBluh);
    padding:0 1rem
}
.custum_input_css .mat-form-field-label {
    color:#3787e6db;
    font-size:.857rem!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius:2px!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius:2px!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border:2px solid currentColor!important;
    border-right:none!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border:2px solid currentColor;
    border-left-style:none;
    border-right-style:none
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border:2px solid currentColor!important;
    border-left:none!important
}
.custum_input_css .mat-form-field-infix {
    border-top:0 solid transparent
}
.custum_input_css .mat-form-field-label-wrapper {
    top:-.643rem
}
.custum_input_css .drop_bg .mat-form-field-flex .mat-form-field-outline {
    background:#e6f1ff
}
.custum_input_css .drop_bg .mat-form-field-flex .mat-form-field-label {
    color:#3787e6eb
}
.custum_input_css .mat-select-value {
    color:#000;
    font-size:.857rem
}
.custum_input_css .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform:translateY(0)!important
}
.custum_input_css .mat-select-arrow {
    color:var(--clBlua);
    margin:-.357rem .286rem;
    border-left:.429rem solid transparent;
    border-right:.429rem solid transparent;
    border-top:.429rem solid;
    position:relative;
    right:-.429rem
}
.custum_input_css .select_time .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform:translate(4px,-3px)!important
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-label {
    top:1.84375em;
    margin-top:.071rem
}
.custum_input_css .mat-select-trigger {
    height:1.214em
}
.custum_input_css .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper .mat-error {
    color:var(--clReda)
}
.custum_input_css .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color:var(--clReda)
}
.custum_input_css .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,.custum_input_css .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color:var(--clReda)
}
.custum_input_css .custom_suffix_txt.mat-form-field-appearance-outline .mat-form-field-suffix {
    top:-.2em
}
textarea.custom_input {
    height:auto!important
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-infix {
    padding:3px 3px 6px 0
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-flex {
    padding:0 5px 0 10px
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-flex:hover {
    padding:0 5px 0 10px
}
.cus_txt_area textarea::-webkit-scrollbar {
    width:8px;
    background-color:#f4f9ff;
    cursor:pointer
}
.cus_txt_area textarea::-webkit-scrollbar-thumb {
    border-radius:4px;
    cursor:pointer;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:var(--clBlua)
}
.mat-input-element::placeholder {
    color:var(--clBlkb);
    font-weight:500
}
.cus_txt_area textarea {
    font-size:1rem
}
.cus_txt_area .mat-form-field-label {
    color:var(--clBlua)
}
.cus_txt_area .mat-form-field-wrapper {
    padding-bottom:6px
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-end,.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border:2px solid currentColor
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border:2px solid currentColor
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-right-style:none
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-left-style:none
}
.cus_txt_area .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-left-style:none;
    border-right-style:none
}
.feedback_input .mat-form-field-appearance-legacy .mat-form-field-underline {
    display:none!important
}
.shipment_by_lane .mat-radio-container {
    background:#f0f0f0!important;
    border-radius:50%
}
.shipment_by_lane .mat-radio-inner-circle {
    background-color:#3787e6!important
}
.shippper_subs_box .mat-radio-outer-circle { background: #fff;  } /* top: 2px; width: 16px; height: 16px; */
/* .shippper_subs_box .mat-radio-inner-circle { left: -3.7px !important; top: -3.4px !important; height: 27px !important; width: 27px !important; } */
.shippper_subs_box .mat-radio-button .mat-radio-ripple { left: calc(50% - 12px); top: calc(50% - 12px); height: 24px; width: 24px; }
/* .shippper_subs_box .mat-radio-label-content { padding-left: 2px;} */
.shipment_by_lane .mat-radio-outer-circle {
    border:none!important
}
.shipment_by_lane .mat-radio-group {
    display:flex;
    justify-content:space-between;
    min-width:450px
}
.custom_redio_btn .mat-radio-button.mat-accent .mat-radio-inner-circle,.custom_redio_btn .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.custom_redio_btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.custom_redio_btn .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color:var(--clBlua)
}
.custom_redio_btn .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:var(--clBlua)
}
.custom_redio_btn .mat-radio-button .mat-radio-label {
    margin:0
}
.custom_redio_btn .mat-radio-outer-circle {
    border-color:var(--clBlua)
}
.custom_checkbox .mat-checkbox-frame {
    border-width:1px
}
.custom_checkbox .mat-checkbox-inner-container {
    height:16px;
    margin-right:8px;
    width:16px
}
.custom_checkbox .mat-checkbox-frame {
    border-color:#82828280;
    width:16px;
    height:16px
}
.custom_checkbox .mat-checkbox-checked.mat-accent .mat-checkbox-background,.custom_checkbox .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color:var(--clBlua)
}
.custom_checkbox .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.custom_checkbox .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background:var(--clBlua)
}
.custom_checkbox .mat-checkbox-layout .mat-checkbox-label {
    line-height:20px;
    color:var(--clBlkb)
}
.custom_checkbox .mat-checkbox .mat-checkbox-ripple {
    display:none
}
.tab_container_inpput .mat-form-field-wrapper {
    padding-bottom:0!important
}
.tab_container_inpput .mat-form-field-infix {
    padding:12px 0!important;
    border-top:0!important
}
.tab_container_inpput .resend_otp_space .mat-form-field-infix {
    padding:12px 0 0!important
}
.map_shadow {
    margin-top:-60px;
    display:inline-block;
    width:100%
}
#map_shadow_top {
    position:relative
}
#map_shadow_top::after {
    content:'';
    position:absolute;
    z-index:1;
    background:-moz-linear-gradient(top,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%);
    background:-webkit-linear-gradient(top,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%);
    background:linear-gradient(to bottom,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%);
    width:100%;
    height:80px;
    left:0;
    top:0
}
.driver_view_detail #map_shadow_top::after {
    z-index:2
}
#map {
    position:relative;
    z-index:1;
    width:100%;
    height:calc(100vh - 15vh);
    margin-top:70px
}
.network_conr #map {
    position:relative;
    z-index:1;
    width:100%;
    height:calc(100vh - 15vh);
    margin-top:0
}
.pickupActive {
    color:var(--clWhta)!important;
    background:var(--clBlua)!important
}
.dropActive {
    color:var(--clWhta)!important;
    background:var(--clBlua)!important
}
.routeActive {
    color:var(--clWhta)!important;
    background:var(--clBlua)!important
}
agm-map {
    height:80vh;
    width:100%;
    min-height:550px
}
.card_map_overlay #map {
    position:relative;
    z-index:2;
    width:100%;
    height:600px;
    margin:0 0 25px 0
}
::-webkit-scrollbar {
    width:4px
}
::-webkit-scrollbar-track {
    background:#f1f1f1
}
::-webkit-scrollbar-thumb {
    background:#888
}
::-webkit-scrollbar-thumb:hover {
    background:#555
}
.hidden_scrollBar::scrollbar {
    width:0;
    background:0 0
}
.hidden_scrollBar::scrollbar-thumb {
    box-shadow:none;
    background:0 0
}
.hidden_scrollBar::-webkit-scrollbar {
    width:0;
    background:0 0
}
.hidden_scrollBar::-webkit-scrollbar-thumb {
    box-shadow:none;
    background:0 0
}
.hidden_scrollBar {
    scrollbar-color:#cfcece00 #cfcece00;
    scrollbar-width:none
}
.mat-select-panel {
    scrollbar-width:thin;
    border-radius:4px
}
.mat-select-panel::scrollbar {
    width:6px;
    height:6px;
    background-color:#ecf1f700
}
.mat-select-panel::scrollbar-thumb {
    border-radius:4px;
    box-shadow:inset 0 0 6px #0000001a;
    background-color:#d7d6d6
}
.mat-select-panel::-webkit-scrollbar {
    width:6px;
    height:6px;
    background-color:#ecf1f700
}
.mat-select-panel::-webkit-scrollbar-thumb {
    border-radius:4px;
    -webkit-box-shadow:inset 0 0 6px #0000001a;
    background-color:#d7d6d6
}
.v_scrollBar {
    overflow-y:auto;
    overflow-x:hidden;
    scrollbar-width:thin;
    border-radius:4px
}
.v_scrollBar::scrollbar {
    width:6px;
    height:6px;
    background-color:#ecf1f700
}
.v_scrollBar::scrollbar-thumb {
    border-radius:4px;
    box-shadow:inset 0 0 6px #0000001a;
    background-color:#d7d6d6
}
.v_scrollBar::-webkit-scrollbar {
    width:6px;
    height:6px;
    background-color:#ecf1f700
}
.v_scrollBar::-webkit-scrollbar-thumb {
    border-radius:4px;
    -webkit-box-shadow:inset 0 0 6px #0000001a;
    background-color:#d7d6d6
}
.list_scroll { height:calc(100vh - 225px); overflow-y:auto; overflow-x:hidden; scrollbar-width:thin; border-radius:4px; }
.list_scroll::scrollbar { width: 4px; height:.571rem; background: #888; } 
.list_scroll::scrollbar:hover { background: #555; }
.list_scroll::scrollbar-thumb { border-radius:4px; box-shadow:inset 0 0 6px rgba(0,0,0,0.5); background: #f4f9ff; }
.list_scroll::-webkit-scrollbar { width: 4px; height:.571rem; background: #888; }
.list_scroll::-webkit-scrollbar:hover { background: #555; }
.list_scroll::-webkit-scrollbar-thumb { border-radius:4px; -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); background: #f1f1f1; }
.list_scroll::-moz-scrollbar { width: 4px; height:.571rem; background: #888; }
.list_scroll::-moz-scrollbar:hover { background: #555; }
.list_scroll::-moz-scrollbar-thumb { border-radius:4px; -moz-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); background: #f1f1f1; }
.man_carrier_scroll {
    height:calc(100vh - 185px);
    overflow-y:auto;
    overflow-x:hidden
}
.man_carrier_scroll::scrollbar {
    width:.571rem;
    height:.571rem;
    background-color:#f4f9ff
}
.man_carrier_scroll::scrollbar-thumb {
    border-radius:2px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:#afcef3;
    text-align:center;
    margin:0 10px;
    max-height:2rem;
    height:100%
}
.man_carrier_scroll::-webkit-scrollbar {
    width:.571rem;
    height:.571rem;
    background-color:#f4f9ff
}
.man_carrier_scroll::-webkit-scrollbar-thumb {
    border-radius:2px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:#afcef3;
    text-align:center;
    margin:0 10px;
    max-height:2rem;
    height:100%
}
.list_scroll.dark_scroll::scrollbar {
    width:.571rem;
    height:.571rem;
    background-color:#f4f9ff
}
.list_scroll.dark_scroll::scrollbar-thumb {
    border-radius:4px;
    box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:var(--clBlua)
}
.list_scroll.dark_scroll::-webkit-scrollbar {
    width:.571rem;
    height:.571rem;
    background-color:#f4f9ff
}
.list_scroll.dark_scroll::-webkit-scrollbar-thumb {
    border-radius:4px;
    box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:var(--clBlua)
}
.custom_dropdown {
    position:relative
}
.custom_dropdown .dropdown-menu.show {
    position:absolute;
    will-change:top,left,bottom,right;
    right:0;
    left:auto!important;
    min-width:3vw;
    float:right;
    top:30px!important;
    border:none
}
.custom_dropdown .dropdown-menu {
    padding:6px 0;
    margin:0;
    box-shadow:0 6px 22px -4px rgb(0 0 0 / 15%);
    border-radius:4px
}
.custom_dropdown .dropdown-menu .dropdown-item {
    padding:5px 0;
    font-size:1rem;
    line-height:18px;
    font-weight:500;
    color:var(--clBlkb);
    min-width:10rem;
    margin:0 7px 0 14px;
    font-display:swap;
    font-family:'Clash Display'
}
.custom_dropdown .dropdown-menu .dropdown-item:focus,.custom_dropdown .dropdown-menu .dropdown-item:hover {
    box-shadow:none;
    background-color:transparent!important;
    color:var(--clBlka)!important
}
.custom_dropdown .dropdown-menu a:active,.custom_dropdown .dropdown-menu a:focus,.custom_dropdown .dropdown-menu a:hover {
    box-shadow:none;
    background-color:transparent!important;
    color:var(--clBlua)!important
}
.dropdown_menu_list .dropdown-menu .dropdown-item:hover {
    color:var(--clBlka)!important;
    background:inherit!important;
    box-shadow:none!important
}
.dropdown_menu_list .dropdown-menu .dropdown-item.disabled_drop_btn {
    background:0 0;
    color:transparent!important
}
.dropdown_menu_list .dropdown-menu .dropdown-item.disabled_drop_btn:hover {
    cursor:no-drop;
    color:transparent!important
}
.current_page_title {
    font-size:1.143rem;
    line-height:1rem;
    font-weight:600;
    color:var(--clBlka)
}
.backTO {
    height:16px;
    margin:0 0 0 -5px;
    display:flex;
    align-items:center;
    cursor:pointer
}
.back_link {
    font-size:.857rem;
    font-weight:500;
    color:var(--clBlua)!important;
    margin:0 0 0 -.357rem;
    cursor:pointer;
    display:flex
}
.back_link i {
    font-size:1.286rem;
    padding:0 .143rem .143rem 0
}
.view-doc-bg .mat-dialog-container {
    box-shadow:none;
    background:#fff0;
    color:#000000de
}
.cdk-overlay-dark-backdrop {
    background:#000000b8
}
.view-doc-bg .mat-dialog-container {
    padding:24px 15px
}
.data_not_found {
    padding:2rem 0 1.071rem 0;
    border-radius:6px
}
.data_not_found_head {
    text-align:center;
    margin:0 0 2rem
}
.doc_success_tag:before {
    left:2.5rem;
    content:"";
    position:absolute;
    width:0;
    height:0;
    box-sizing:border-box;
    transform-origin:0 0;
    transform:rotate(90deg);
    border-radius:0 0 0 4px;
    border:.714rem solid #9a9a9a;
    border-color:transparent transparent var(--clGrna) var(--clGrna)
}
.doc_danger_tag:before {
    left:2.5rem;
    content:"";
    position:absolute;
    width:0;
    height:0;
    box-sizing:border-box;
    transform-origin:0 0;
    transform:rotate(90deg);
    border-radius:0 0 0 4px;
    border:.714rem solid #9a9a9a;
    border-color:transparent transparent var(--clReda) var(--clReda)
}
.popup_activity {
    margin:2.5rem 0 0 0
}
.popup_activity .header_mess {
    margin:0 0 3.929rem 0
}
.popup_activity div span {
    text-decoration:underline
}
.cdk-overlay-backdrop.g-transparent-backdrop.cdk-overlay-backdrop-showing {
    background:var(--clBlka);
    opacity:75%
}
.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    background:var(--clBlka);
    opacity:0
}
.mat-dialog-container {
    box-shadow:0 0 10px 0 #c0dbff;
    border-radius:10px!important
}
.cdk-overlay-container .cdk-overlay-pane.view-doc-bg {
    max-width:100vw!important
}
.contant_info_header {
    display:flex;
    flex-direction:row;
    margin:0;
    min-height:38px
}
.view_head_left {
    flex-basis:8%;
    align-items:center;
    display:flex
}
.info_header_left {
    flex-basis:16%;
    align-items:center;
    display:flex
}
.view_head_right .q1,.view_head_right .q2 {
    flex-basis:50%
}
.view_head_right {
    flex-basis:92%;
    margin:0;
    display:flex;
    align-items:center
}
.info_header_right {
    flex-basis:84%;
    display:flex;
    align-items:center;
    margin:0 0 0 2rem
}
.info_header_right .q1 {
    flex-basis:50%;
    display:flex;
    align-items:center
}
.info_header_right .q2 {
    flex-basis:50%;
    display:flex;
    align-items:center;
    justify-content:flex-end
}
.custom_badge {
    background:red;
    position:absolute;
    top:10px;
    left:19px;
    padding:2px 3px;
    color:var(--clWhta)
}
.card .card-header {
    z-index:2!important
}
.text_truncate {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis
}
.H_l_right .H_l_anchor.H_l_horizontal {
    display:none
}
.view_pro_abl {
    background:var(--clGrna);
    font-size:.857rem;
    padding:.143rem .571rem;
    border-radius:4px
}
.view_pro_block {
    background:var(--clReda);
    font-size:.857rem;
    padding:.143rem .571rem;
    border-radius:4px
}
.current_status {
    color:var(--clWhta);
    padding:3px 6px;
    border-radius:4px;
    font-size:12px;
    font-weight:400
}
.status_bg_success {
    background:var(--clGrna)
}
.status_bg_danger {
    background:var(--clReda)
}
.status_bg_primary {
    background:var(--clBlua)
}
.dri_stats {
    font-size:.857rem;
    padding:3px 6px;
    border-radius:4px;
    color:var(--clWhta)
}
.stats_available {
    background:var(--clGrna)
}
.stats_invited {
    background:#88178800;
    border:1px solid var(--clGrna);
    border-radius:4px;
    padding:2px 10px;
    color:var(--clGrna)
}
.stats_inTransit {
    background:var(--clBlua)
}
.stats_suspend {
    background:var(--clReda)
}
.hide_input .mat-form-field-flex {
    display:none
}
.select_date_ip .mat-form-field-suffix {
    background:var(--clBlke);
    width:40px;
    height:48px;
    padding:6px 0;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px
}
.signIn_signUp input.custom_input::-moz-placeholder {
    line-height:revert!important
}
.custom_matSuffix_icon .mat-form-field-suffix {
    position:absolute;
    right:5px;
    top:17px
}
.custom_matSuffix_icon .mat-button-focus-overlay,.custom_matSuffix_icon .mat-button-ripple.mat-ripple {
    display:none
}
.custom_matSuffix_icon .mat-form-field-suffix {
    position:absolute;
    right:10px;
    top:16px
}
@media (max-width:767px) {
    html {
        font-size:12px
    }
    .custum_button {
        min-width:5.429rem
    }
    .view_head_left {
        flex-basis:15%
    }
    .view_head_right {
        flex-basis:85%
    }
    .edit_card_body {
        padding:1.429rem
    }
    .edit_card_head {
        flex-direction:column!important;
        align-items:center
    }
    .edit_card_head_right {
        width:100%!important;
        padding:0!important;
        margin:1.071rem 0 0 0
    }
    .input_gap {
        margin:0
    }
    .pagination_list {
        bottom:0
    }
    .info_header_right .q2 {
        flex-basis:100%
    }
    .info_header_left {
        display:none
    }
    .info_header_right {
        flex-basis:100%;
        margin:0
    }
    .notification_calander .badge_notification {
        max-width:45px
    }
    .notification_calander .see_more_notifi {
        padding:2px 3px
    }
}
@media (max-width:620px) {
    .vertical_tab {
        flex-direction:column
    }
    .tab_list {
        flex-basis:100%;
        flex-direction:column
    }
    .srch_top {
        max-width:inherit
    }
    .list-group {
        flex-direction:row;
        margin:15px 0
    }
    .tab_list .list-group-item.active {
        background-color:#d4e5fb;
        border-bottom:2px solid
    }
    .tab_list .list-group-item {
        line-height:1rem;
        padding:10px 2px;
        width:100%;
        text-align:center
    }
    .tab_list .list-group-item.active:before {
        display:none
    }
    .list-group-item:last-child {
        margin-bottom:auto
    }
    .tabcontent {
        margin:0;
        flex-basis:100%
    }
    .list_scroll {
        height:calc(100vh - 170px);
        overflow-y:auto;
        overflow-x:auto
    }
    .pagination_list {
        bottom:-60px;
        width:100%;
        overflow-x:auto;
        margin:0!important
    }
    .card_border.card-body {
        width:720px;
        padding:5px 5px!important
    }
    .pagination_list .page-link,.pagination_list .page-link:focus {
        margin:0 3px;
        padding:0 5px
    }
    .info_header_right .q2 {
        flex-basis:100%
    }
    .info_header_left {
        display:none
    }
    .info_header_right {
        flex-basis:100%;
        margin:0
    }
}
@media (min-width:768px) and (max-width:1023px) {
    .container {
        padding:0
    }
    html {
        font-size:13px
    }
}
.pac-container {
    border-radius:4px;
    border-top:none;
    box-shadow:0 6px 22px -4px #00000026;
    bottom:inherit!important;
    right:inherit!important
}
.pac-container.pac-logo:after {
    display:none!important
}
.pac-item {
    border-top:1px solid var(--clBlke);
    font-size:11px!important;
    color:var(--clBlkb)!important;
    font-weight:400;
    padding:5px 12px
}
.pac-item:hover {
    background-color:#f9f9f9
}
.pac-item-query {
    font-size:11px!important;
    color:var(--clBlkb)
}
.pac-container {
    margin:5px 0 0 0;
    position:absolute!important
}
.network_sugBox .pac-container {
    margin:20px 0 0 -35px
}
.userProfile_sugBox .pac-container {
    position:fixed!important
}
.onBoarding_sugBox .pac-container {
    margin:5px 0 0 0;
    position:fixed!important
}
.pac-container .pac-item {
    white-space:pre-line;
    line-height:17px
}
.dark_bg_skton .loader {
    background:0 0/200px 100% no-repeat #3f8ce74a!important;
    background-image:linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.6),rgba(255,255,255,0))!important
}
.loader_m_0 .loader {
    margin:0!important
}
.loader_mb_5 .loader {
    margin:0 0 5px!important
}
.loader_mb_0 .loader {
    margin-bottom:0!important
}
.loader_mt_0 .loader {
    margin-top:0!important
}
.loader_mt_5 .loader {
    margin-top:5px!important
}
.loader_mt_10 .loader {
    margin-top:10px!important
}
.loader_mb_10 .loader {
    margin-bottom:10px!important
}
.loader_m_0 .loader.circle {
    margin:0!important
}
.skeleton_mb_2 .loader {
    margin-bottom:2px!important
}
.w_bg_skton .loader {
    background-image:linear-gradient(90deg,#f7faff,#fffffff0,#f7faff)!important
}
.view_ship_index {
    position:relative;
    z-index:2
}
.detail_view_tab .mat-tab-header,.detail_view_tab .mat-tab-nav-bar {
    border-bottom:1px solid #c7e5ff00;
    border-radius:6px 6px 0 0
}
.detail_view_tab .mat-tab-labels {
    background:#d4e5fb
}
.detail_view_tab .mat-tab-group>.mat-tab-header .mat-tab-label {
    max-width:183px;
    padding:0;
    height:60px;
    font-size:16px;
    font-weight:600;
    opacity:.5;
    border-right:2px solid #e6f1ff
}
.detail_view_tab .mat-tab-label-active {
    opacity:1!important;
    color:var(--clBlka);
    font-weight:600;
    background:var(--clWhta)
}
.detail_view_tab .mat-tab-group.mat-primary .mat-ink-bar {
    background-color:transparent
}
.view_loca_info .H_ib_body {
    box-shadow:0 0 10px 0 #c0dbff;
    bottom:-5.7em;
    right:-20px
}
.view_loca_info .H_ib_tail {
    bottom:-5em;
    right:-1.5em
}
.view_loca_info .H_ib_close {
    width:12px;
    height:12px
}
.view_loca_info .H_ib_content {
    min-width:300px;
    font:12px Roboto,sans-serif;
    line-height:22px;
    font-weight:600;
    color:#0f1621cc;
    margin:10px 28px 15px 15px
}
.view_loca_info .H_ib_content .loca_point_info span {
    font-size:16px;
    font-weight:600;
    color:var(--clBlua);
    padding-left:12px
}
.view_loca_info .H_ib_content .loca_point_info .info_line {
    font-size:14px;
    font-weight:500;
    color:var(--clBlka);
    margin:0 0 1px;
    padding:0 0 0 10px
}
.view_loca_info .H_ib_content .loca_point_info .info_line span {
    font-size:14px;
    font-weight:300;
    color:var(--clBlka);
    padding-left:1px
}
.view_loca_info .H_ib_content .loca_point_info .info_botm_line {
    color:var(--clBlua);
    font-weight:500;
    font-size:12px;
    cursor:pointer
}
.view_loca_info .H_ib_content .loca_point_info_h {
    display:flex;
    align-items:center;
    min-height:40px;
    margin:0 0 10px
}
.map_pins::after {
    content:"";
    position:absolute;
    width:0;
    height:0;
    margin-left:-.5em;
    bottom:-12px;
    left:15px;
    box-sizing:border-box;
    border-top:6px solid var(--clBlka);
    border-bottom:6px solid #0000;
    border-left:6px solid var(--clBlka);
    border-right:6px solid #0000;
    border-color:#e6414100 #e6414100 var(--clWhta) var(--clWhta);
    transform-origin:0 0;
    transform:rotate(-45deg);
    box-shadow:-6px 6px 9px -4px #006dff9c
}
.map_pins .map_pins_txt {
    font-size:11px;
    font-weight:600;
    margin:0 0 0 10px;
    display:-webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    overflow:hidden;
    line-height:16px
}
.loca_point_info_h img {
    width:40px
}
.pin_point_info {
    padding:3px 12px 12px 59px
}
.map_point_icon img {
    width:50px;
    padding:5px
}
.map_pins {
    background:var(--clWhta);
    border-radius:2px;
    box-shadow:0 0 10px 0 #c0dbff;
    display:-webkit-box;
    display:block;
    cursor:pointer;
    -webkit-box-align:center;
    align-items:center
}
.loca_point_info_h {
    padding:5px 10px
}
.loca_point_info_h span {
    color:var(--clBlua);
    font-size:14px;
    font-weight:500;
    margin:0 0 0 10px
}
.pin_point_info {
    padding:3px 12px 12px
}
.loca_point_info {
    width:20rem;
    cursor:default
}
.info_line {
    margin:0 0 5px;
    font-weight:500
}
.info_botm_line {
    background:#d4e5fb;
    padding:3px 10px;
    border-radius:2px;
    font-size:10px;
    font-weight:600;
    color:var(--clBlka);
    cursor:pointer
}
.loca_point_info {
    display:block
}
.map_pins1 {
    display:flex;
    max-width:160px;
    padding:5px 10px;
    align-items:center
}
.map_pins:hover .map_pins1 {
    display:none
}
.boderLeft_g {
    border-left:5px solid var(--clGrna)
}
.boderLeft_y {
    border-left:5px solid #fdd835
}
.boderLeft_d {
    border-left:5px solid var(--clReda)
}
.sdsdsdsd {
    background:var(--clBlka);
    color:var(--clWhta);
    padding:20px
}
.common-Button {
    padding:0 8px;
    font-size:12px
}
.common-ButtonGroup .common-Button {
    margin:10px 0 12px 10px
}
.common-Button--default {
    background:var(--clBlua)
}
.common-BodyText {
    font-size:16px;
    line-height:26px
}
.message_overview .mat-tab-header::after {
    position:absolute;
    width:100%;
    height:2px;
    content:'';
    background:#c7daf200;
    bottom:0;
    z-index:0
}
.message_overview .mat-tab-header {
    background:#fff0;
    border:none
}
.message_overview .mat-ink-bar::after {
    position:absolute;
    content:'';
    height:2px;
    width:158px;
    background:var(--clWhta);
    bottom:-56px
}
.message_overview .mat-ink-bar {
    position:absolute;
    height:2px;
    transition:none;
    background:var(--clBlua)!important;
    max-width:160px!important;
    width:auto
}
.message_overview .mat-tab-label {
    height:70px;
    opacity:1;
    min-width:auto;
    max-width:fit-content;
    width:fit-content;
    font-size:16px;
    color:var(--clBlkb);
    font-weight:500;
    padding:0 1rem;
    display:inline-flex
}
.message_overview .mat-tab-label .mat-tab-label-content {
    font-display:swap;
    font-family:'Clash Display'
}
.message_overview .mat-tab-label-active .mat-tab-label-content {
    color:var(--clBlka)
}
.message_overview .mat-tab-body-wrapper {
    margin:-30px 0 0;
    z-index:0
}
.mat-ripple-element {
    background:#e6f1ff52
}
@media (min-width:1023px) {
    .filter_selection .md-drppicker.shown.drops-down-left,.filter_selection .md-drppicker.shown.drops-down-right {
        display:block;
        width:600px
    }
    .filter_selection .md-drppicker .ranges {
        background:#f5f9ff!important;
        height:280px
    }
    .filter_selection .md-drppicker .btn {
        padding:0 10px;
        background-color:var(--clBlua)!important;
        color:var(--clWhta)!important;
        line-height:28px!important;
        min-width:90px;
        margin:0 10px 8px 0
    }
    .filter_selection .md-drppicker .ranges ul li button.active {
        background-color:var(--clBlua)!important
    }
    .filter_selection .md-drppicker .ranges ul li:hover {
        background-color:#e6f1ff!important
    }
    .filter_selection .md-drppicker td.available:hover,.filter_selection .md-drppicker th.available:hover {
        background-color:#e6f1ff!important
    }
    .filter_selection .md-drppicker td.active,.filter_selection .md-drppicker td.active:hover {
        background-color:var(--clBlua)!important
    }
    .filter_selection .md-drppicker td.in-range {
        background-color:#e6f1ff
    }
    .filter_selection .md-drppicker td.off,.filter_selection .md-drppicker td.off.end-date,.filter_selection .md-drppicker td.off.in-range,.filter_selection .md-drppicker td.off.start-date {
        background-color:var(--clWhta)!important
    }
    .filter_selection .md-drppicker td.active.end-date,.filter_selection .md-drppicker td.active.start-date {
        background-color:var(--clBlua)!important
    }
    .filter_selection .md-drppicker.hidden {
        transition:0s
    }
    .filter_selection .md-drppicker .clear svg {
        display:none
    }
}
.advance_srch_tab .advance_srch_tab_head .mat-tab-label.mat-ripple {
    background:#87b7f0;
    font-size:16px;
    color:var(--clWhta);
    opacity:1;
    font-weight:500
}
.advance_srch_tab .advance_srch_tab_head .mat-tab-label.mat-ripple.mat-tab-label-active {
    background:#f2f8ff!important;
    color:var(--clBlua)!important
}
.advance_srch_tab_head .mat-ink-bar {
    background:0 0!important
}
.advance_srch_tab_head .mat-tab-header {
    border-bottom:none
}
@media (min-width:768px) and (max-width:1366px) {
    .upload_certificate_pop {
        width:32vw!important
    }
}
.vertical_step_wizard .mat-form-field-wrapper {
    padding-bottom:.5em
}
.unread_badge .mat-badge-medium .mat-badge-content {
    width:16px;
    height:16px;
    line-height:15px;
    font-size:10px;
    font-weight:400;
    top:-18px!important;
    left:-12px!important;
    background:var(--clReda)
}
.short_name {
    display:flex;
    align-items:center;
    justify-content:center;
    width:64px;
    height:64px;
    background:var(--clBlke);
    color:#000000c7;
    border-radius:4px;
    font-size:1.429rem;
    font-weight:500;
    text-transform:uppercase
}
.short_name_view {
    display:flex;
    align-items:center;
    justify-content:center;
    width:40px;
    height:40px;
    background:#d4d4d4;
    color:var(--clBlka);
    border-radius:50%;
    font-size:1.143rem;
    font-weight:500;
    text-transform:uppercase
}
.mat-dialog-container::-webkit-scrollbar {
    width:6px;
    background-color:#f4f9ff
}
.mat-dialog-container::-webkit-scrollbar-thumb {
    border-radius:2px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1);
    background-color:#afcef3;
    text-align:center;
    padding:0 10px
}
.selection_src {
    background-image:none!important;
    width:98%
}
#mapNetwork {
    width:100%;
    height:100%
}
.custom_matSuffix_iconN .mat-form-field-suffix {
    top:1.5rem
}
.selection_tab .mat-tab-header {
    background:#fff0;
    border:none
}
.selection_tab .mat-ink-bar::after {
    position:absolute;
    content:'';
    height:2px;
    width:158px;
    background:var(--clWhta);
    bottom:-56px
}
.selection_tab .mat-ink-bar {
    position:absolute;
    height:2px;
    transition:none;
    background:var(--clBlua)!important
}
.selection_tab .mat-tab-label {
    height:40px;
    opacity:1;
    min-width:120px;
    max-width:156px;
    font-size:16px;
    color:var(--clBlkb);
    font-weight:500;
    padding:0 1rem
}
.selection_tab .mat-tab-label-active .mat-tab-label-content {
    color:var(--clBlka)
}
.custum_input_css .shipper_reg .mat-form-field-subscript-wrapper {
    margin:.5rem 0 0
}
.circle_check .mat-checkbox-frame {
    border-radius:50%
}
.circle_check .mat-checkbox-checked.mat-accent .mat-checkbox-background,.circle_check .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color:var(--clGrna);
    border-radius:50%
}
.circle_check .mat-checkbox-inner-container {
    height:32px;
    width:32px
}
.circle_check .mat-checkbox-frame {
    border-width:1px
}
.circle_check .mat-checkbox-frame {
    border-color:#d8d8d8
}
.circle_check .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.circle_check .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background:var(--clGrna)
}
.circle_check .mat-checkbox-checked .mat-checkbox-checkmark {
    padding:7px
}
.circle_check .mat-checkbox-layout {
    margin:0
}
.cirCheck_22 .mat-checkbox-inner-container {
    height:22px;
    width:22px
}
.cirCheck_22 .mat-checkbox-checked .mat-checkbox-checkmark {
    padding:4px
}
.radioCircel_check .mat-radio-outer-circle {
    box-sizing:border-box;
    height:32px;
    left:0;
    position:absolute;
    top:0;
    transition:border-color ease 280ms;
    width:32px;
    border-width:1px;
    border-style:solid;
    border-radius:50%
}
.radioCircel_check .mat-radio-outer-circle {
    border-color:#d8d8d8
}
.radioCircel_check .mat-radio-inner-circle:before {
    content:"\e93a";
    width:30px;
    height:30px;
    position:relative;
    font-display:swap;
    font-family:icomoon;
    color:var(--clWhta);
    font-size:40px;
    top:-4px;
    left:0
}
.radioCircel_check .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:var(--clGrna);
    background:var(--clGrna)
}
.radioCircel_check .mat-radio-ripple.mat-ripple {
    display:none
}
.radioCircel_check .mat-radio-button.mat-accent .mat-radio-inner-circle,.radioCircel_check .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.radioCircel_check .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.radioCircel_check .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color:var(--clGrna)
}
.radioCircel_24 .mat-radio-outer-circle {
    height:24px;
    width:24px
}
.radioCircel_24 .mat-radio-inner-circle:before {
    width:24px;
    height:24px;
    font-size:32px;
    top:-10px;
    left:-2px
}
.card_inpt input::placeholder {
    color:var(--clBlka)!important
}
.mat-form-field {
    display:inline!important
}
.custom_input {
    font-size:14px!important;
    line-height:18px!important;
    font-weight:500!important;
    color:var(--clBlka)!important;
    width:100%!important;
    padding:11px 16px!important;
    border:none!important;
    border-radius:4px!important;
    background:var(--clBluh)!important;
    box-sizing:border-box!important
}
.custom_input:focus {
    outline:0!important;
    box-shadow:none!important;
    background:var(--clBluh)!important
}
.custom_input::placeholder {
    font-size:14px!important;
    font-weight:500!important;
    color:var(--clBlkb)!important;
    line-height:1rem!important
}
.custom_input .mat-select-placeholder {
    font-size:14px;
    font-weight:500;
    color:var(--clBlkb)
}
.custom_input .mat-select-value-text {
    font-size:14px;
    font-weight:500;
    color:var(--clBlka)
}
.ic_dropdown mat-select {
    background-image:none!important
}
.ic_dropdown .mat-select-arrow-wrapper:before {
    content:"\e900";
    font-display:swap;
    font-family:icomoon;
    font-size:15px;
    color:var(--clBlkb)
}
.ic_dropdownQ .mat-select-arrow-wrapper:before { font-size: 23px !important; font-weight: 600 !important;}
.ic_dropdown .mat-select-arrow {
    display:none
}

.ic_calander .mat-button-wrapper:before {
    content:"\e907";
    font-display:swap;
    font-family:icomoon;
    font-size:16px;
    display:block;
    margin:-1px 0 0 0;
    color:var(--clBlkb);
    padding:0 6px 0 0;
    cursor:pointer
}
.ic_calander .mat-form-field-underline {
    display:none
}
.lg_rgcal .mat-button-wrapper:before {
    margin:-7px 0 0 0!important;
    padding:0!important
}
.lg_rgOnbrd .mat-button-wrapper:before {
    margin:-3px 0 0 0!important;
    padding:0!important
}
.ic_calander .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,.ic_calander .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    right:0;
    top:-.5px
}
.ic_calander .mat-icon-button .mat-button-wrapper svg {
    display:none!important
}
.ic_calander .mat-button-focus-overlay,.ic_calander .mat-button-ripple.mat-ripple {
    display:none
}
.ic_calander .mat-form-field-flex {
    display:flex;
    align-items:center
}
.ic_calander .custom_input {
    border-radius:4px 0 0 4px!important
}
.ic_password .mat-icon-button {
    margin:-5px 0 0
}
.ic_password .mat-select-arrow-wrapper:before {
    content:"\e900";
    font-display:swap;
    font-family:icomoon;
    font-size:16px;
    color:var(--clBlkb)
}
.ic_checked {
    margin:3px 0 0
}
.ic_checked:before {
    content:"\e93a";
    font-display:swap;
    font-family:icomoon;
    font-size:16px
}
.ic_time .mat-select-arrow-wrapper:before {
    content:"\e900";
    font-display:swap;
    font-family:icomoon;
    font-size:16px;
    color:var(--clBlkb)
}
.ic_time .mat-select-arrow {
    display:none
}
.cu_loca_map #map {
    position:relative!important;
    margin:0!important;
    height:calc(100vh - 65px)!important
}
.dots_menu {
    font-size:24px;
    border-radius:4px;
    cursor:pointer
}
.dots_menu:hover {
    background:#d8d8d8
}
.cu_loca_map .marker {
    max-width:20px;
    max-height:48px
}
.cu_loca_map .marker::before {
    position:absolute;
    content:"";
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/map-icons/map-pin-network.png);
    width:53px;
    height:53px;
    z-index:-1;
    top:-5px;
    left:-16px
}
.cu_loca_map .no_profile_marker {
    border-radius:50%;
    font-size:12px!important;
    font-weight:600;
    letter-spacing:.5px;
    max-width:22px;
    max-height:22px;
    background-color:var(--clBlua);
    position:absolute;
    z-index:0;
    color:var(--clBlka);
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    text-transform:uppercase
}
.marker img {
    width:28px;
    height:28px;
    display:flex;
    position:absolute;
    top:-4px;
    left:-5px;
    border-radius:50%;
    cursor:pointer
}
.marker.active_profile {
    box-shadow:0 0 24px 12px var(--clBlka);
    max-height:20px;
    max-width:20px;
    z-index:1
}
.detail_mapbox .marker {
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    font-size:12px!important;
    font-weight:600;
    letter-spacing:1px;
    max-width:20px;
    max-height:20px;
    background-color:var(--clBlua);
    position:absolute;
    z-index:0;
    color:var(--clBlka);
    text-transform:uppercase
}
.src_cancel_icons .mat-form-field-suffix {
    display:flex;
    align-items:center
}
.mapOn_shipView #map {
    height:500px!important
}
.mapOn_shipView #map .mapboxgl-canvas {
    width:100%!important;
    height:100%!important
}
.empty_state {
    padding:0;
    background:var(--clWhta);
    border-radius:4px
}
.empty_state_con {
    height:calc(100vh - 190px);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    width:41%;
    margin:0 auto
}
.emptyState_action {
    margin:1.5rem 0 0
}
.mat_dropdown.mat-option .mat-option-text {
    font-size:14px;
    font-weight:400;
    color:var(--clBlkb)
}
.mat_dropdown.mat-option.mat-selected:not(.mat-option-multiple) {
    background:#3787e612
}
.mat_dropdown.mat-option:focus,.mat_dropdown.mat-option:hover {
    background:rgb(241 247 253 / 50%)
}
.mat-select-panel {
    box-shadow:0 4px 12px 0 rgb(0 0 0 / 16%)!important;
    border-radius:4px!important
}
.ng-trigger.ng-trigger-transformPanel.mat-select-panel::-webkit-scrollbar {
    width:.571rem!important;
    height:.571rem!important;
    background-color:#ecf1f700!important
}
.ng-trigger.ng-trigger-transformPanel.mat-select-panel::-webkit-scrollbar-thumb {
    border-radius:4px!important;
    box-shadow:inset 0 0 6px rgba(0,0,0,.1)!important;
    background-color:#f4f9ff!important
}
.ng-trigger.ng-trigger-transformPanel.mat-select-panel::-moz-scrollbar {
    -moz-width:.571rem!important;
    -moz-height:.571rem!important;
    -moz-background-color:#ecf1f700!important
}
.ng-trigger.ng-trigger-transformPanel.mat-select-panel::-moz-scrollbar-thumb {
    -moz-border-radius:4px!important;
    -moz-box-shadow:inset 0 0 6px rgba(0,0,0,.1)!important;
    -moz-background-color:#f4f9ff!important
}
[type=button],[type=reset],[type=submit] {
    -webkit-appearance:button;
    -moz-appearance:inherit
}
.btn_32 {
    font-size:14px!important;
    font-weight:400!important;
    line-height:14px!important;
    height:32px!important
}
.loader_spinner {
    position:absolute;
    width:100%;
    max-width:40px;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    -webkit-animation:spinLoader .6s steps(12,end) infinite;
    animation:spinLoader .6s steps(12,end) infinite
}
@-webkit-keyframes spinLoader {
    from {
        transform:translate(-50%,-50%) rotate(0turn)
    }
    to {
        transform:translate(-50%,-50%) rotate(1turn)
    }
}
@keyframes spinLoader {
    from {
        transform:translate(-50%,-50%) rotate(0turn)
    }
    to {
        transform:translate(-50%,-50%) rotate(1turn)
    }
}
.newtast1 p {
    margin:0 0 0;
    color:var(--clBlkb);
    font-weight:400
}
.Empty_State_body {
    width:auto;
    background:var(--clWhta);
    border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5rem 0;
    margin:0 16px 22px;
    box-shadow:0 8px 16px 0 #041a3433;
    border-radius:6px
}
.Empty_State_body_withou_box {
    width:auto;
    background:var(--clWhta);
    border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5rem 0;
    margin:0 16px 22px;
    border-radius:6px
}
.Empty_State_con {
    text-align:center;
    margin:0 auto 0;
    max-width:55%
}
.Empty_State_img {
    max-width:250px;
    margin:0 auto
}
@media (max-width:460px) {
    .Empty_State_con {
        max-width:100%
    }
}
.newtast1 p span {
    color:var(--clBlkb)!important;
    font-weight:400!important
}
.list_option {
    font-size:30px;
    line-height:30px;
    margin:0 0 0 1rem;
    cursor:pointer;
    border-radius:4px
}
.list_option:hover {
    background:#d8d8d8
}
.notification_icon {
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0 6px 0 0;
    color:var(--clWhta);
    border-radius:50%;
    padding:2px;
    max-width:40px;
    max-height:40px;
    width:100%
}
.notification_icon:before {
    font-display:swap;
    font-family:icomoon;
    width:40px;
    height:40px;
    font-size:24px;
    padding:8px;
    border-radius:50%;
    display:flex;
    align-items:center
}
.success {
    background:var(--clGrna)!important
}
.warning {
    background:var(--clyloa)!important
}
.danger {
    background:var(--clReda)!important
}
.spinner>div {
    width:6px;
    height:6px;
    margin-right:2px;
    background-color:#333;
    border-radius:100%;
    display:inline-block;
    -webkit-animation:sk-bouncedelay 1.4s infinite ease-in-out both;
    animation:sk-bouncedelay 1.4s infinite ease-in-out both
}
.spinner .bounce1 {
    -webkit-animation-delay:-.32s;
    animation-delay:-.32s
}
.spinner .bounce2 {
    -webkit-animation-delay:-.16s;
    animation-delay:-.16s
}
@-webkit-keyframes sk-bouncedelay {
    0%,100%,80% {
        -webkit-transform:scale(0)
    }
    40% {
        -webkit-transform:scale(1)
    }
}
@keyframes sk-bouncedelay {
    0%,100%,80% {
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    40% {
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
.custom_toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color:var(--clBlua)
}
.custom_toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color:hsl(213 78% 56% / .54)
}
.custom_toggle.clr_tgl .mat-slide-toggle .mat-slide-toggle-thumb {
    background-color:var(--clBlua)
}
.custom_toggle.clr_tgl .mat-slide-toggle .mat-slide-toggle-bar {
    background-color:hsl(213 78% 56% / .54)
}
.tab_header .mat-tab-label {
    height:30px;
    padding:0 8px 10px;
    opacity:1;
    text-align:center;
    align-items:flex-end;
    font-size:1.143rem;
    font-weight:500;
    color:var(--clBlkb)
}
.tab_header .mat-tab-label-active {
    color:var(--clBlka)
}
.tab_header .mat-tab-header {
    border-bottom:none
}
.tab_header .mat-tab-group.mat-primary .mat-ink-bar {
    background-color:var(--clBlua)
}
.status_bg_W .progragess_lines .lines {
    background:var(--clWhta)
}
.mat-tooltip {
    font-size:16px!important;
    font-weight:500!important
}
.alert .alert_action {
    padding:2px 0 2px 22px;
    position:initial!important;
    display:block;
    cursor:pointer
}
.alert span {
    display:block;
    max-width:92%;
    word-break:break-word
}
.alert.alert-with-icon .close.mat-button {
    border:none;
    min-width:auto;
    width:24px;
    height:24px;
    padding:0
}
.alert.alert-with-icon .close i {
    position:initial;
    left:auto;
    top:auto;
    margin:auto
}
.alert.alert-with-icon i {
    font-size:30px;
    display:block;
    left:15px;
    position:absolute;
    top:50%;
    margin-top:-15px;
    color:#fff
}
.dashboard_top_part .mat-tab-label {
    padding:0;
    margin:0 35px 0 0;
    min-width:60px;
    justify-content:inherit;
    opacity:1;
    color:var(--clBlkb);
    font-weight:500;
    font-size:14px
}
.dashboard_top_part .mat-tab-label-active {
    color:var(--clBlka)!important
}
.dashboard_top_part .mat-tab-header {
    border-bottom:none;
    margin:0 0 12px
}
.network_adv_src .unic_search .mat-form-field-infix {
    border-top:none;
    padding:.7rem 0 .7rem 1rem
}
.network_adv_src .form-control:read-only {
    background-image:none
}
.advance_src .srch_top .mat-form-field-wrapper {
    padding-bottom:0
}
.network_adv_src .custom_input .mat-select-placeholder {
    font-size:16px;
    font-weight:400;
    color:var(--clBlkb)
}
.advance_src .mat-form-field-prefix {
    order:inherit
}
.network_adv_src .user_search .srch_top .mat-form-field-prefix {
    order:0
}
.network_adv_src .user_search .srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    padding:11px 4px 11px 11px
}
.disabled_txt {
    opacity:.5
}
.disabled_txt:hover {
    cursor:no-drop
}
.upload_document .mat-form-field-infix {
    display:flex;
    align-items:center
}
.upload_document .custom_input {
    border-radius:2px 0 0 2px
}
.profile_container .card_containerr image {
    opacity:.25!important
}
.strength_slider .mat-slider-horizontal {
    width:100%;
    padding:0 0;
    height:8px
}
.strength_slider .mat-slider-horizontal .mat-slider-wrapper {
    height:6px;
    top:0;
    left:0;
    right:0;
    border-radius:4px;
    overflow:hidden
}
.strength_slider .mat-slider-horizontal .mat-slider-track-wrapper {
    height:6px
}
.strength_slider .mat-slider-horizontal .mat-slider-track-background {
    height:6px
}
.strength_slider .mat-slider-horizontal .mat-slider-track-fill {
    height:6px;
    border-radius:4px
}
.strength_slider .mat-slider-track-background {
    background-color:#f1f1f1
}
.strength_slider:hover .mat-slider-track-background {
    background-color:#f1f1f1
}
.strength_slider .mat-slider-horizontal .mat-slider-ticks-container {
    height:6px
}
.strength_slider .mat-slider-horizontal .mat-slider-ticks {
    height:6px
}
.strength_slider .mat-slider-disabled .mat-slider-thumb {
    border-width:0;
    transform:initial;
    right:-10px;
    bottom:-3px;
    box-sizing:border-box;
    width:20px;
    height:6px;
    border:none;
    border-radius:0 4px 4px 0
}
.strength_slider.low_profile_strenth .mat-accent .mat-slider-thumb,.strength_slider.low_profile_strenth .mat-accent .mat-slider-thumb-label,.strength_slider.low_profile_strenth .mat-accent .mat-slider-track-fill {
    background-color:var(--clReda)
}
.strength_slider.medium_profile_strenth .mat-accent .mat-slider-thumb,.strength_slider.medium_profile_strenth .mat-accent .mat-slider-thumb-label,.strength_slider.medium_profile_strenth .mat-accent .mat-slider-track-fill {
    background-color:#ff0
}
.strength_slider.high_profile_strenth .mat-accent .mat-slider-thumb,.strength_slider.high_profile_strenth .mat-accent .mat-slider-thumb-label,.strength_slider.high_profile_strenth .mat-accent .mat-slider-track-fill {
    background-color:var(--clGrna)
}
.inviteDriver_pop .mat-tab-body-content {
    height:100%;
    overflow:inherit
}
.cus_toggle.mat-button-toggle-group,.cus_toggle.mat-button-toggle-standalone {
    border-radius:5px;
    width:100%;
    box-shadow:none;
    border:1px solid #e6e6e6;
    height:44px;
    align-items:center;
    padding:4px;
    font-size:14px;
    font-weight:600
}
.cus_toggle .mat-button-toggle {
    white-space:nowrap;
    position:relative;
    width:50%
}
.cus_toggle .mat-button-toggle-checked {
    background-color:var(--clBlua);
    border-radius:5px;
    color:var(--clWhta)!important
}
.cus_toggle .mat-button-toggle {
    color:var(--clBlkb)
}
.cus_tab .mat-tab-label {
    padding:0 15px!important;
    min-width:auto;
    opacity:.5
}
.cus_tab .mat-tab-label.mat-tab-label-active {
    opacity:1
}
.radio_color {
    background-image:none!important
}
.radio_color .mat-radio-button.mat-accent .mat-radio-inner-circle,.radio_color .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.radio_color .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.radio_color .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color:var(--clBlua)
}
.radio_color .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:var(--clBlua)
}
.Sacramento_txt {
    font-display:swap;
    font-family:Sacramento;
    color:var(--clBlka);
    font-weight:400
}
.GreatVibes_txt {
    font-display:swap;
    font-family:'Great Vibes';
    color:var(--clBlka);
    font-weight:400
}
.Yellowtail_txt {
    font-display:swap;
    font-family:Yellowtail;
    color:var(--clBlka);
    font-weight:400
}
.Tangerine_txt {
    font-display:swap;
    font-family:Tangerine;
    color:var(--clBlka);
    font-weight:400
}
.Cedarville_txt {
    font-display:swap;
    font-family:Cedarville;
    color:var(--clBlka);
    font-weight:400
}
.DancingScript_txt {
    font-display:swap;
    font-family:'Dancing Script';
    color:var(--clBlka);
    font-weight:400
}
.checked_radio .mat-radio-outer-circle {
    border-color:#cecece
}
.checked_radio .mat-radio-label-content {
    color:var(--clBlkb)
}
.checked_radio .mat-radio-outer-circle {
    border-width:1px
}
.checked_radio .material-icons {
    display:none
}
.checked_radio .mat-radio-checked .mat-radio-label-content {
    position:relative
}
.checked_radio .mat-radio-checked .mat-radio-label-content .material-icons {
    display:inherit;
    position:absolute;
    left:-22px;
    top:-3px;
    color:#03a3e1;
    background:var(--clWhta);
    border-radius:50%
}
.onboarding .mat-form-field-subscript-wrapper {
    margin:0
}
.cus_matTab .mat-tab-label {
    height:70px;
    opacity:1;
    padding:0 14px;
    min-width:auto
}
.cus_matTab .mat-tab-label-content {
    font-size:16px;
    font-weight:500;
    color:var(--clBlkb)
}
.cus_matTab .mat-tab-label-active .mat-tab-label-content {
    color:var(--clBlka)
}
.cus_matTab .mat-tab-header {
    border:none;
    padding:0 15px
}
.cus_matTab .mat-tab-group.mat-primary .mat-ink-bar {
    background-color:var(--clBlua)
}
.cus_matTab .mat-tab-body-content {
    overflow:hidden
}
.home_shipmentList .mat-tab-label {
    height:44px
}
.notificationDrop_menu .cus_matTab .mat-tab-label {
    height:42px
}
.content_loader {
    text-align:center;
    margin:15px 0 5px
}
.loader_image img {
    animation:contentLaderRotation 2s infinite linear
}
@keyframes contentLaderRotation {
    from {
        transform:rotate(0)
    }
    to {
        transform:rotate(359deg)
    }
}
.network_bg_map canvas.mapboxgl-canvas {
    height:100%!important;
    width:100%!important;
    min-height:100%
}
.network_bg_map #map {
    height:100%;
    width:100%;
    margin:0
}
.show-section {
    display:block
}
.hide-section {
    display:none
}
.map_cards_container .long_card_scroll {
    min-height:155px;
    max-height:calc(100vh - 155px);
    height:auto;
    margin:0
}
.sm_mapCard_scroll .tray_card_con {
    box-shadow:none!important;
    margin:0 0 6px 0!important
}
.map_sm_cardDetail .side_contaier {
    max-width:inherit!important;
    background:0 0!important;
    position:inherit!important
}
.map_sm_cardDetail .side_content {
    height:calc(100vh - 245px)!important
}
.map_sm_cards .side_content {
    height:calc(100vh - 200px)!important
}
.error_mess {
    color:var(--clReda);
    font-size: 11px;
    line-height:13px;
    font-weight:500
}
.ship_avance_src .srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color:var(--clWhta);
    padding:8px 10px;
    height:38px
}
.bs_tab_src .srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    padding:3px 10px
}
.assign_ship_headr .srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    padding:3px 10px
}
.card_container .mat-stepper-horizontal {
    background:#0000
}
.card_container .mat-horizontal-stepper-header {
    display:flex;
    height:44px;
    overflow:hidden;
    align-items:center;
    padding:0 24px;
    width:33%;
    justify-content:center
}
.card_container .mat-stepper-horizontal-line {
    border-top-width:1px;
    border-top-style:solid;
    flex:auto;
    height:0;
    margin:0 -16px;
    min-width:32px;
    display:none
}
.card_container .mat-horizontal-stepper-header-container {
    background:var(--clWhta);
    border-radius:8px;
    box-shadow:0 8px 16px 0 #041a3433
}
.card_container .mat-horizontal-stepper-header.cdk-focused .mat-step-label-selected {
    color:var(--clWhta)!important
}
.card_container .mat-step-label {
    font-size:16px;
    font-weight:500;
    color:var(--clBlkb)!important;
    display:flex;
    align-items:center
}
.card_container .mat-step-label .mat-step-text-label {
    font-display:swap;
    font-family:'Clash Display';
    display:flex;
    align-items:center
}
.card_container .shipment mat-step-header:nth-child(1) {
    background:var(--clBlua);
    border-radius:8px;
    color:var(--clWhta)!important
}
.card_container .shipment mat-step-header:nth-child(3) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .shipment mat-step-header:nth-child(5) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .freightRate mat-step-header:nth-child(1) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .freightRate mat-step-header:nth-child(3) {
    background:var(--clBlua);
    border-radius:8px;
    color:var(--clWhta)!important
}
.card_container .freightRate mat-step-header:nth-child(5) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .selectedCarrier mat-step-header:nth-child(1) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .selectedCarrier mat-step-header:nth-child(3) {
    background:var(--clWhta);
    border-radius:8px;
    color:var(--clBlkb)!important
}
.card_container .selectedCarrier mat-step-header:nth-child(5) {
    background:var(--clBlua);
    border-radius:8px;
    color:var(--clWhta)!important
}
.card_container .mat-step-label-selected {
    color:var(--clWhta)!important
}
.card_container .mat-horizontal-content-container {
    overflow:inherit;
    padding:0
}
.crt_Ship_body .card_container .mat-horizontal-stepper-header {
    width:50%
}
.rounded_h_tab .mat-horizontal-stepper-header-container {
    border-radius:40px;
    box-shadow:0 5px 10px #0000001a
}
.rounded_h_tab .freightRate mat-step-header:nth-child(1),.rounded_h_tab .freightRate mat-step-header:nth-child(3),.rounded_h_tab .freightRate mat-step-header:nth-child(5),.rounded_h_tab .selectedCarrier mat-step-header:nth-child(1),.rounded_h_tab .selectedCarrier mat-step-header:nth-child(3),.rounded_h_tab .selectedCarrier mat-step-header:nth-child(5),.rounded_h_tab .shipment mat-step-header:nth-child(1),.rounded_h_tab .shipment mat-step-header:nth-child(3),.rounded_h_tab .shipment mat-step-header:nth-child(5) {
    border-radius:40px
}
.assign_carrier .circle_check .mat-checkbox-inner-container {
    height:24px;
    width:24px
}
.assign_carrier .circle_check .mat-checkbox-checked .mat-checkbox-checkmark {
    padding:4px
}
.pay_method .custom_redio_btn .mat-radio-label {
    flex-direction:row-reverse;
    min-width:300px;
    margin:0 3px;
    justify-content:space-between;
    padding:15px 12px;
    border:1px solid var(--clBlkd);
    border-radius:6px
}
.pay_method .custom_redio_btn .mat-radio-checked .mat-radio-label {
    flex-direction:row-reverse;
    min-width:300px;
    background:#e7f1ff;
    margin:0 3px;
    justify-content:space-between;
    padding:15px 12px;
    border:1px solid var(--clBlub)
}
.pay_method .custom_redio_btn .mat-radio-label-content {
    font-size:16px;
    font-weight:400;
    color:var(--clBlka)
}
.pay_method .custom_redio_btn .mat-radio-outer-circle {
    border-color:var(--clBlkd)
}
.pay_buy_lods .mat-radio-button {
    width:48%
}
.payment_method .custom_redio_btn .mat-radio-label {
    padding:15px 12px;
    border:1px solid var(--clBlkd);
    border-radius:6px;
    display:block
}
.payment_method .custom_redio_btn .mat-radio-checked .mat-radio-label {
    background:#e7f1ff;
    border:1px solid var(--clBlub);
    display:block
}
.payment_method .mat-radio-checked .payment_selectionr {
    color:var(--clGrna)
}
.payment_method .pay_text {
    padding-left:12px
}
.payment_method .mat-radio-checked .pay_text {
    color:var(--clBlka)
}
.payment_method .mat-radio-group .mat-radio-container {
    display:none
}
.payment_method .mat-radio-label-content {
    display:block;
    padding:0
}
.payment_method .mat-radio-button .payment_selection {
    display:flex
}
.payment_method .mat-radio-button .payment_selectionr {
    display:none
}
.payment_method .mat-radio-button.mat-radio-checked .payment_selectionr {
    display:block;
    margin-left:18px
}
.prefix_txt .mat-form-field-prefix {
    width:10%;
    height:2.85rem;
    padding:.9rem;
    border:none;
    border-radius:4px 0 0 4px;
    background:var(--clBluh)
}
.crt_Ship_body .mat-horizontal-stepper-header .mat-step-icon {
    display:none
}
.input_prefix .mat-form-field-flex {
    align-items:center
}
.input_prefix .custom_input {
    border-radius:0 4px 4px 0!important
}
.input_prefix .prefix_text {
    border-radius:4px 0 0 4px!important
}
.input_suffix .mat-form-field-flex {
    align-items:center
}
.input_suffix .custom_input {
    border-radius:4px 0 0 4px!important
}
.input_suffix .suffix_text {
    border-radius:0 4px 4px 0!important
}
.mat-checkbox-disabled,.mat-select-disabled {
    cursor:no-drop!important;
    opacity:.5
}
.mat-select-disabled .mat-select-trigger {
    cursor:no-drop!important
}
.shipmetList_con .inProgress .loca_icon,.shipmetList_con .past .loca_icon {
    color:var(--clBlua)
}
.shipmetList_con .inProgress .sc_loca_picup:before,.shipmetList_con .past .sc_loca_picup:before {
    color:#3786e640
}
.loca_iconFlag .picup_blue {
    width:14px;
    margin:0 0 0 10px;
    filter:grayscale(100%);
    opacity:.4
}
.shipmetList_con .inProgress .loca_iconFlag .picup_blue,.shipmetList_con .past .loca_iconFlag .picup_blue {
    filter:grayscale(0);
    opacity:1
}
.activeBidding .sc_locations,.availableLoads .sc_locations,.pastBidding .sc_locations {
    margin:16px -16px 0;
    padding:16px 16px 0;
    border-top:1px solid var(--clBlke)
}
.filtr_top_arrow .ic_calander .mat-form-field-suffix {
    display:flex;
    align-items:center;
    background:var(--clBluh)
}
.filtr_top_arrow .ic_calander .clear_date {
    display:flex!important;
    align-items:center!important;
    height:40px;
    cursor:pointer;
    color:var(--clBlkb)
}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    opacity:1
}
.card_detail .mat-form-field-wrapper {
    padding:0
}
.card_detail .mat-radio-label-content {
    width:100%!important
}
.Buy_USDC_popup mat-dialog-container {
    padding:0
}
.Buy_USDC_popup .header_popup {
    padding:24px 24px 12px
}
.Buy_USDC_popup .mat-tab-header {
    background:var(--clBlug);
    border-top:1px solid #d8d8d8;
    border-bottom:none;
    padding-top:8px
}
.Buy_USDC_popup .mat-tab-list {
    margin:0 24px
}
.Buy_USDC_popup .mat-tab-body-content {
    padding:32px 24px
}
.Buy_USDC_popup .mat-radio-label-content {
    width:100%
}
.Buy_USDC_popup .mat-tab-label {
    font-size:16px;
    color:var(--clBlkb);
    font-weight:400;
    min-width:auto;
    padding:0;
    margin:0 46px 0 0;
    opacity:1
}
.Buy_USDC_popup .mat-tab-label-active {
    color:var(--clBlka);
    font-weight:500;
    opacity:1
}
.expention_panel .mat-expansion-panel {
    border:none;
    box-shadow:none
}
.expention_panel mat-expansion-panel-header {
    height:auto!important;
    padding:8px 0!important
}
.expention_panel mat-expansion-panel-header:hover {
    background:0 0!important
}
.ElementsApp .InputElement:placeholder,.ElementsApp:placeholder {
    font-display:swap;
    font-family:roboto!important;
    font-size:14px!important;
    font-weight:500!important
}
.ElementsApp,.ElementsApp .InputElement {
    font-display:swap;
    font-family:roboto!important;
    font-size:14px!important;
    font-weight:500!important
}
.usd_iconM {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign.svg);
    background-repeat:no-repeat;
    width:24px;
    height:24px;
    background-size:contain;
    display:inline-flex;
    margin:0
}
.usdc_iconM {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usdc.svg);
    background-repeat:no-repeat;
    width:24px;
    height:24px;
    background-size:contain;
    display:inline-flex;
    margin:0 5px 0 0
}
.usd_white_iconM {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign-white.svg);
    background-repeat:no-repeat;
    width:24px;
    height:24px;
    background-size:contain;
    display:inline-flex;
    margin:0 -4px 0 2px
}
.usd_iconS {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign-blue-24.svg);
    background-repeat:no-repeat;
    width:8px;
    height:15px;
    background-size:contain;
    display:inline-block
}
.awardedBids .usd_iconS {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-g.svg)
}
.usdc_iconS {
    background-image:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg);
    background-repeat:no-repeat;
    width:16px;
    height:16px;
    background-size:contain;
    display:inline-block;
    margin:0 4px 0 0
}
.loadHistory_panel .line_before {
    margin:0 0 22px -16px
}
.withdraw_tokens .mat-form-field-flex {
    display:flex;
    align-items:center
}
#visa1x {
    width:42px;
    height:16px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -2px -4px
}
#visa_line1x {
    width:36px;
    height:24px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px -33px
}
#mastercard1x {
    width:36px;
    height:22px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 261px
}
#master_debit1x {
    width:36px;
    height:24px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 228px
}
#discover1x {
    width:36px;
    height:24px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 194px
}
#amex1x {
    width:44px;
    height:18px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) 0 -175px
}
#diners1x {
    width:36px;
    height:26px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 128px
}
#jcb1x {
    width:36px;
    height:26px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 93px
}
#unionpay1x {
    width:36px;
    height:24px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -3px 58px
}
#interac1x {
    width:24px;
    height:24px;
    background:url(https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -10px 24px
}
.pub_network_map_card .sm_mapCard_scroll {
    max-height:calc(100vh - 245px)!important
}
.pub_network_map_card .map_sm_cards .side_content {
    height:calc(100vh - 400px)!important
}
.pub_network_map_card .side_content {
    height:calc(100vh - 335px)!important
}
.pub_network_map_card .side_headerr span {
    display:flex;
    background:#ffffff42;
    padding:2px 5px;
    border-radius:4px;
    box-shadow:0 2px 8px 0 #041a3433
}
.pub_network_map_card .side_headerr span:hover {
    background:var(--clWhta);
    color:var(--clBlka)!important;
    box-shadow:0 2px 8px 0 #041a3463
}
.pub_network_map_card .header_bar {
    padding:0!important;
    position:absolute;
    right:20px;
    top:16px;
    z-index:1
}
.hCard_skt .ske_t .ske_tl {
    width:15%
}
.hCard_skt .ske_t .ske_tr {
    width:85%
}
.df_c {
    display:flex;
    align-items:center
}
.shipmnt-side-panel .Empty_State_body {
    box-shadow:none;
    padding:2rem 0;
    margin:0
}
.shipmnt-side-panel .Empty_State_con {
    max-width:100%!important
}
.shipmnt-side-panel .Empty_State_img {
    margin:0 auto 24px
}
.initial_name {
    display:flex;
    align-items:center;
    justify-content:center;
    background:#3787e6;
    color:#fff;
    text-transform:uppercase
}
.initial_c_32 {
    min-width:32px;
    max-width:32px;
    height:32px;
    border-radius:20px;
    font-size:14px;
    line-height:15px;
    font-weight:500
}
.initial_c_34 {
    min-width:34px;
    max-width:34px;
    height:34px;
    border-radius:20px;
    font-size:12px;
    line-height:12px;
    font-weight:500
}
.initial_c_40 {
    min-width:40px;
    max-width:40px;
    height:40px;
    border-radius:24px;
    font-size:18px;
    line-height:19px;
    font-weight:500
}
.initial_c_46 {
    min-width:46px;
    max-width:46px;
    height:46px;
    border-radius:24px;
    font-size:18px;
    line-height:19px;
    font-weight:500
}
.initial_c_64 {
    min-width:64px;
    max-width:64px;
    height:64px;
    border-radius:32px;
    font-size:26px;
    line-height:26px;
    font-weight:500
}
.initial_r_28 {
    min-width:28px;
    max-width:28px;
    height:28px;
    border-radius:4px;
    font-size:12px;
    line-height:12px;
    font-weight:500
}
.initial_r_30 {
    min-width:30px;
    max-width:30px;
    height:30px;
    border-radius:4px;
    font-size:14px;
    line-height:16px;
    font-weight:500
}
.initial_r_32 {
    min-width:32px;
    max-width:32px;
    height:32px;
    border-radius:4px;
    font-size:14px;
    line-height:16px;
    font-weight:500
}
.initial_r_38 {
    min-width:38px;
    max-width:38px;
    height:38px;
    border-radius:4px;
    font-size:16px;
    line-height:16px;
    font-weight:500
}
.initial_r_40 {
    min-width:40px;
    max-width:40px;
    height:40px;
    border-radius:4px;
    font-size:18px;
    line-height:18px;
    font-weight:500
}
.initial_r_42 {
    min-width:42px;
    max-width:42px;
    height:42px;
    border-radius:4px;
    font-size:18px;
    line-height:18px;
    font-weight:500
}
.initial_r_46 {
    min-width:46px;
    max-width:46px;
    height:46px;
    border-radius:4px;
    font-size:24px;
    line-height:24px;
    font-weight:500
}
.initial_r_48 {
    min-width:48px;
    max-width:48px;
    height:48px;
    border-radius:6px;
    font-size:24px;
    line-height:24px;
    font-weight:500
}
.initial_r_44 {
    min-width:44px;
    max-width:44px;
    height:44px;
    border-radius:4px;
    font-size:18px;
    line-height:16px;
    font-weight:500
}
.initial_r_64 {
    min-width:64px;
    max-width:64px;
    height:64px;
    border-radius:4px;
    font-size:26px;
    line-height:26px;
    font-weight:500
}
.selection_src .mat-select-search-clear {
    display:none!important
}
.clear_dateIcon .mat-form-field-suffix {
    display:flex;
    align-items:center;
    background:#f6f6f6
}
.clear_dateIcon .mat-form-field-suffix .clear_date {
    cursor:pointer
}
.wn_shipment_expired {
    width:40px;
    height:40px;
    background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-expired.svg) no-repeat center center
}
.wn_shipment_cancelled {
    width:40px;
    height:40px;
    background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-cancelled.svg) no-repeat center center
}
.wn_shipment_unavailable {
    width:40px;
    height:40px;
    background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-unavailable.svg) no-repeat center center
}
.wn_shipment_bid_lost {
    width:40px;
    height:40px;
    background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-bid-lost.svg) no-repeat center center
}
.cus_matTab .mat-tab-label-content {
    font-size:16px;
    font-weight:500;
    color:var(--clBlkb);
    height:70px;
    font-display:swap;
    font-family:'clash display'
}
.mat-tab-label-content {
    font-display:swap;
    font-family:'clash display'
}
.mat-button-toggle-label-content {
    font-display:swap;
    font-family:'clash display'
}
.transaction_container.cus_matTab .mat-tab-label {
    height:55px
}
.transaction_container.cus_matTab .mat-tab-label-content {
    height:55px
}
.transaction_container .mat-ink-bar {
    height:3px
}
.bus_ser_con .mat-tab-body-content {
    overflow:hidden
}
.close_popup_Icon {
    display:flex;
    align-items:center;
    justify-content:flex-end
}
.close_Icon {
    font-size:18px;
    line-height:18px;
    font-weight:400;
    color:var(--clWhta);
    cursor:pointer;
    padding:2px;
    background:#0000001a;
    border-radius:6px;
    width:26px;
    height:26px;
    display:flex;
    align-items:center;
    justify-content:center
}
.close_Icon:hover {
    background:#00000080
}
.dropdown-menu {
    border:none;
    box-shadow:0 8px 16px 0 #00000026
}
.dropdown-menu .dropdown-item {
    min-height:32px;
    width:auto;
    display:flex;
    flex-flow:nowrap;
    align-items:center;
    font-weight:500
}
.prefix_input_align .mat-form-field-flex {
    display:flex;
    align-items:center
}
.la_term .mat-radio-label {
    white-space:inherit
}
.la_term .mat-radio-group {
    display:flex
}
.prefix_input_align .mat-form-field-flex {
    display:flex;
    align-items:center
}
.feed_back_popup .spinner-border {
    display:inline-block;
    width:16px;
    height:16px;
    vertical-align:-.125em;
    border:2px solid currentColor;
    border-right-color:transparent;
    border-radius:50%;
    animation:.75s linear infinite spinner-border;
    margin:0 6px 0 0;
    color:#fff
}
.upload_dock .btn,.upload_document .btn {
    border:none
}
.upload_dock .custom_input {
    border-top-right-radius:0!important;
    border-bottom-right-radius:0!important
}
.select_sorting .mat-select .mat-select-value {
    text-align:left
}
.toggle_lable mat-button-toggle-group .mat-button-toggle-label-content {
    font-size:14px;
    line-height:26px;
    font-weight:500
}
.lable_checkbox .custom_checkbox .mat-checkbox-layout .mat-checkbox-label {
    color:var(--clBlka);
    font-size:14px;
    font-weight:500
}
.lable_toggle .mat-button-toggle-label-content {
    font-size:14px;
    line-height:26px;
    font-weight:500
}
.asl_icon .mat-form-field-suffix {
    position:absolute;
    right:6px;
    top:13px
}
.lo_list_icn {
    font-size:12px;
    line-height:12px;
    font-weight:500;
    color:#fff;
    padding:0 6px;
    border-radius:4px;
    margin:0 0 0 8px;
    border:1px solid transparent
}
.Yes.lo_list_icn {
    background:var(--clGrna);
    border:1px solid var(--clGrna)
}
.No.lo_list_icn {
    background:0 0;
    border:1px solid #818181;
    color:#818181
}
mat-checkbox .mat-checkbox-layout {
    word-break:break-word;
    white-space:break-spaces
}
.order_list_con .mat-tab-body-content {
    padding:86px 0 0
}
.sub_tabs .mat-tab-body-wrapper {
    margin:-30px 0 0;
    z-index:0
}
.sub_tabs .mat-tab-label {
    min-width:56px;
    max-width:84px;
    padding:0 6px
}
.sub_tabs .mat-ink-bar {
    width:84px!important
}
.time_select_con .mat-radio-container {
    width:14px;
    height:14px
}
.time_select_con .mat-radio-outer-circle {
    height:14px;
    width:14px
}
.time_select_con .mat-radio-inner-circle {
    height:14px;
    width:14px
}
.time_select_con .mat-radio-button .mat-radio-ripple {
    left:calc(50% - 10px);
    top:calc(50% - 10px);
    height:20px;
    width:20px
}
.time_select_con .custom_input {
    padding:6px 12px 3px!important
}
.time_select_con mat-radio-button .mat-radio-label-content {
    color:#818181;
    font-weight:400
}
.time_select_con mat-radio-button.mat-radio-checked .mat-radio-label-content {
    color:#000
}
.picupDrop_Container .custum_input_css .mat-form-field-wrapper {
    padding:0 0 .7rem 0
}
.picupDrop_Container .custum_input_css .mat-form-field-subscript-wrapper {
    margin-top:8px
}
.chat_room .srch_top .mat-form-field-appearance-fill .mat-form-field-flex {
    padding:0 0 0 15px
}
.chat_room .srch_top .mat-form-field-wrapper {
    padding-bottom:0
}
.trmrtur_input .custom_input {
    padding-right:24px!important
}
.trmrtur_input .custom_input {
    padding-right:24px!important
}
.ad_srch_l .mat-form-field-wrapper {
    padding:0
}
.ad_srch_l .mat-form-field-infix {
    border:none
}
.ad_srch_l .custom_input {
    background:0 0!important
}
.bdm_map .mapboxgl-popup-content {
    padding:12px!important
}
.bdm_map .map_marker {
    font-size:14px;
    line-height:18px;
    font-weight:500;
    color:#000;
    text-align:center;
    margin:0;
    opacity:.8
}
.bdm_map .detail_mapbox .marker img {
    max-width:20px;
    max-height:20px;
    position:initial
}
.bdm_map .detail_mapbox .mapboxgl-popup-close-button {
    display:none
}
.bdm_map .pop_hed {
    display:flex
}
.bdm_map .pop_hed .pop_hed_l {
    margin:0 8px 0 0;
    min-width:26px
}
.bdm_map .detail_mapbox .marker {
    max-width:24px;
    max-height:24px
}
.bdm_map .detail_mapbox .marker {
    padding:0
}
.bdm_map .pop_hed .pop_hed_titl {
    margin:0 0 5px;
    display:flex;
    align-items:center;
    min-height:24px
}
.bdm_map .pop_hed .pop_hed_titl span {
    font-display:swap;
    font-family:'Clash Display';
    font-size:13px;
    font-weight:600;
    line-height:13px
}
.bdm_map .pop_hed .dat_tim {
    font-size:11px;
    line-height:14px;
    font-weight:500;
    color:#818181;
    margin:0 0 1px
}
.bdm_map .point_location {
    font-size:11px;
    line-height:13px;
    font-weight:500;
    color:#818181;
    margin:4px 0 0;
    display:flex
}
.bdm_map .mapboxgl-ctrl-bottom-left,.bdm_map .mapboxgl-ctrl-bottom-right {
    display:none
}
.driver_detail_con .shipment_card_T {
    padding:12px 10px 0!important
}
.driver_detail_con .shipment_card {
    margin:0 0 24px!important
}
.driver_detail_con .line_status_txt,.driver_detail_con .ship_id {
    font-size:14px!important;
    margin:0 0 10px!important
}
.driver_detail_con .sc_center .edit_shipmentr {
    width:20px!important;
    height:20px!important
}
.driver_detail_con .d_loca_time,.driver_detail_con .p_loca_time {
    margin:0 0 0 12px!important;
    min-height:55px!important
}
.driver_detail_con .loca_drop,.driver_detail_con .loca_picup {
    font-size:14px!important;
    line-height:17px!important;
    margin:0 0 3px!important
}
.driver_detail_con .loca_drop_time,.driver_detail_con .loca_picup_time {
    font-size:12px!important;
    line-height:16px!important
}
.driver_detail_con .sc_loca_picup:before {
    content:"...."!important
}
.driver_detail_con .loca_iconFlag .picup_blue {
    width:12px!important;
    margin:0 0 0 7px!important
}
.driver_detail_con .loca_icon {
    font-size:22px!important;
    line-height:24px!important
}
.driver_detail_con .special_req_con {
    padding:10px 0 10px!important
}
.driver_detail_con .terms_row img {
    max-width:20px!important
}
.driver_detail_con .fs_16 {
    font-size:14px!important;
    line-height:18px!important
}
.driver_detail_con .pay_txt {
    font-size:14px!important;
    line-height:18px!important
}
.driver_detail_con .payrImg img {
    width:20px!important
}
.driver_detail_con .shipment_card_B {
    padding:10px 12px!important;
    min-height:50px!important
}
.driver_detail_con .s_user_img {
    min-width:36px!important;
    max-width:36px!important;
    min-height:36px!important;
    max-height:36px!important
}
.driver_detail_con .s_user_img img {
    height:32px!important;
    width:32px!important
}
.driver_detail_con .u_detail_value {
    font-size:14px!important;
    line-height:18px!important
}
.driver_detail_con .u_detail_lable {
    font-size:12px!important;
    line-height:14px!important
}
.driver_detail_con .icon_bg {
    width:36px!important;
    height:36px!important
}
.driver_detail_con .mess_icon,.driver_detail_con .phone_icon {
    width:36px!important;
    height:36px!important;
    margin:0 0 0 8px!important
}
.driver_detail_con .edit_ship_icon .dropdown-toggle img {
    max-width:18px!important
}
.driver_detail_con span.shipType {
    font-size:12px!important;
    height:22px!important;
    width:42px!important;
    margin:0 8px 0 0!important
}
.driver_detail_con .sc_center .edit_shipmentr {
    margin:0!important
}
.driver_detail_con .ship_drivr_card .sc_center .edit_shipmentl {
    max-width:90%!important
}
.edit_location_con .custom_checkbox mat-form-field:first-child {
    width: 100%;
}
.edit_location_con .custom_checkbox mat-form-field:nth-child(2) {
    width:465px
}
.country_code_fld mat-select {
    min-width:83px;
    border-top-right-radius:0!important;
    border-bottom-right-radius:0!important;
    height:40px;
    border-right:1px solid #ddd!important;
    overflow:hidden;
    margin:1px 0 0
}
.country_code_fld input {
    border-top-left-radius:0!important;
    border-bottom-left-radius:0!important
}
.material-icons-outlined {
    font-display:swap;
    font-family:'Material Icons'
}
.rating_long_card app-rating-view { display:block; width:100%; margin:-12px 0 13px }
.rating_long_card .rvw_rtg_viw_edt { display: flex; align-items: center;}
.rating_long_card .rating_stars .material-icons { font-size: 16px !important;}
.rating_long_card .total_reviews { margin-left: 10px !important; } 
.small_card_rating {
    margin:-3px 0 8px 0
}
app-side-panel app-rating-view {
    margin:8px 0 0 75px;
    display:block
}
app-shipment-card app-rating-view {
    margin:0;
    display:block
}
.dri_profile_info .ship_side_panel_rating app-rating-view {
    margin:0 0 0 55px;
    display:block
}
.ship_profile_info .ship_side_panel_rating app-rating-view {
    display:block;
    margin:3px 0 0 46px
}
.cc_head_r .mat-radio-label-content {
    color:var(--clBlkb)
}
.cc_head_r .mat-radio-checked .mat-radio-label-content {
    color:var(--clBlka)
}
.tab_panel_con .mat-expansion-panel-header {
    min-height:68px;
    height:auto
}
.tab_panel_con .mat-content {
    overflow:visible
}
.tab_panel_con mat-expansion-panel {
    background:#fff;
    box-shadow:none!important;
    border-radius:10px!important;
    border:1px dashed #cbcbcb
}
.tab_panel_con .mat-expansion-indicator::after {
    color:var(--clBlua);
    border-width:0 3px 3px 0;
    border-radius:2px;
    padding:4px
}
.edit_card_box .custom_input {
    padding:11px 12px!important
}
.side-show-panel .tab_panel_con .line_before {
    font-size:13px!important;
    line-height:17px!important;
    padding:2px 0 2px 12px!important;
    margin:0 0 0 -12px!important
}
.side-show-panel .tab_panel_con .tpc_box {
    padding:6px 8px!important;
    margin:0 -12px
}
.side-show-panel .tab_panel_con[_ngcontent-vmx-c392] .document_container[_ngcontent-vmx-c392] .coi_doc[_ngcontent-vmx-c392] {
    margin:4px 0
}
.side-show-panel .coi_docl img {
    max-width:32px
}
.side-show-panel .tab_panel_con .mat-expansion-panel-header {
    padding:6px 12px
}
.inpt_checkbox mat-checkbox {
    max-width:40px
}
.inpt_checkbox .mat-form-field-infix {
    display:flex
}
.hint_l_side .mat-form-field-hint-spacer {
    display:none
}
.cus_backdrop_class {
    background:#000000a6
}
.cus_popup_vw .mat-dialog-container {
    box-shadow:none;
    background:snow
}
.loadHistory_panel app-trip-history .line_before {
    margin:0 0 30px -16px
}
.loadHistory_panel .event_dateTxt {
    min-width:58px!important;
    margin:0 6px 0 0!important
}
.loadHistory_panel .event_date_yr {
    font-weight:500!important;
    font-size:12px!important;
    line-height:15px!important;
    min-height:34px!important;
    text-align:right
}
.loadHistory_panel .event_date {
    font-size:12px!important;
    line-height:15px!important;
    font-weight:500!important
}
.loadHistory_panel .load_icon {
    min-width:38px!important;
    min-height:38px!important;
    max-width:38px!important;
    margin:0 12px 0 0!important
}
.loadHistory_panel .load_icon img {
    max-width:22px!important
}
.loadHistory_panel .load_his_info:after {
    left:82px!important
}
.loadHistory_panel .list_row {
    margin:0 0 14px!important
}
.loadHistory_panel .event_time {
    padding:4px 8px!important;
    max-width:270px!important;
    width:100%
}
.loadHistory_panel .event_title {
    font-size:14px!important;
    line-height:16px!important;
    margin:0 0 0!important
}
.loadHistory_panel .trip_history_sidePanel .event_title {
    width:100%!important;
    max-width:270px!important
}
.loadHistory_panel .scnd_lable_row_con p {
    line-height:19px
}
.loadHistory_panel .scnd_lable_row_con .event_title {
    font-size:13px!important;
    line-height:15px!important
}
.loadHistory_panel .slrc_icon {
    min-width:38px!important;
    min-height:38px!important;
    max-width:38px!important;
    margin:0 9px 0 0!important
}
.loadHistory_panel .trip_history_sidePanel .location_detailTxt {
    max-width:206px!important
}
.loadHistory_panel .trip_history_sidePanel .scnd_lable_row_con .location_detailTxt {
    max-width:160px!important
}
.loadHistory_panel .slrc_row:after {
    width:16px!important;
    height:16px!important;
    left:-48px!important;
    top:8px!important
}
.loadHistory_panel .load_his_head {
    display:block!important;
    margin:0 0 22px
}
.loadHistory_panel .load_his_head .custom_checkbox {
    margin:6px 0 0 8px
}
.sidePanel_tab app-document-multiple .detail_card_con {
    box-shadow:none;
    padding:0;
    border-radius:0;
    margin:0
}
.sidePanel_tab app-document-multiple .detail_card_con .add_doc_action {
    padding:8px 5px
}
.sidePanel_tab app-document-multiple .detail_card_con .add_doc_action span {
    font-size:12px!important
}
.loadHistory_panel .updated_value_box_2 .sub_point_time {
    left:-98px!important
}
.card_container .tab_buttons .mat-horizontal-stepper-header {
    padding:0 0;
    border-radius:24px
}
.card_container .tab_buttons .mat-step-label-selected {
    color:var(--clWhta)!important;
    width:100%;
    height:100%;
    align-items:center;
    justify-content:center;
    background:var(--clBlua);
    border-radius:8px;
    color:var(--clWhta)!important
}
.card_container .tab_buttons .cus_toggle.mat-button-toggle-group,.card_container .tab_buttons .cus_toggle.mat-button-toggle-standalone {
    padding:0;
    border:none
}
.card_container .tab_buttons .mat-button-toggle-button {
    border:1px solid #818181;
    border-radius:5px;
    font-weight:500;
    color:#818181
}
.card_container .tab_buttons .cus_toggle .mat-button-toggle-checked .mat-button-toggle-button {
    background-color:var(--clBlua);
    border-radius:5px;
    color:var(--clWhta)!important;
    border:1px solid #3787e6
}
.overview_preferred_regions .mat-horizontal-stepper-header {
    padding:0 0;
    border-radius:24px
}
.overview_preferred_regions .mat-step-label-selected {
    color:var(--clWhta)!important;
    width:100%;
    height:100%;
    align-items:center;
    justify-content:center;
    background:var(--clBlua);
    border-radius:8px;
    color:var(--clWhta)!important
}
.overview_preferred_regions .cus_toggle.mat-button-toggle-group,.overview_preferred_regions .cus_toggle.mat-button-toggle-standalone {
    padding:0;
    border:none
}
.overview_preferred_regions .mat-button-toggle-button {
    border:1px solid #818181;
    border-radius:5px;
    font-weight:500;
    color:#818181
}
.overview_preferred_regions .cus_toggle .mat-button-toggle-checked .mat-button-toggle-button {
    background-color:var(--clBlua);
    border-radius:5px;
    color:var(--clWhta)!important;
    border:1px solid #3787e6
}
.mat-pseudo-checkbox {
    color:#d9d9d9
}
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,.mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate {
    border-color:#d9d9d9
}
.mat-selected .mat-pseudo-checkbox.mat-pseudo-checkbox-checked,.mat-selected .mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate {
    border-color:#3787e6
}
.mat-primary .mat-pseudo-checkbox-checked,.mat-primary .mat-pseudo-checkbox-indeterminate {
    background:#fff
}
.mat-pseudo-checkbox-checked::after {
    border-left:2px solid #3787e6!important
}
.mat-pseudo-checkbox::after {
    border-bottom:2px solid #3787e6!important
}
.mat-active .mat-option-text {
    color:#666
}
.mat-selected .mat-option-text {
    color:#3787e6
}
.subscription-billing .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
    transform:scale3d(.6,1,1)!important
}
.subscription-billing .mat-progress-bar-fill::after {
    background-color:#3787e6
}
.mat-progress-bar-buffer {
    background-color:#22b0fc
}
.subscription-billing .mat-progress-bar {
    height:10px!important;
    width:40%!important;
    margin:17px 0
}
.mat-tab-nav-bar.mat-primary .mat-ink-bar,.subscription-billing .mat-tab-group.mat-primary .mat-ink-bar {
    background-color:#3787e6!important
}
.subscription-billing .mat-tab-header,.subscription-billing .mat-tab-nav-bar {
    border:none
}
.subscription-billing .mat-slide-toggle-label {
    flex-direction:row-reverse!important;
    justify-content:space-between;
    width:171px
}
.subscription-billing .mat-slide-toggle-content {
    font-size:15px;
    font-display:swap;
    font-family:'Clash Display';
    font-weight:500;
    color:#818181
}
.sub_billing_page .mat-tab-header,.sub_billing_page .mat-tab-nav-bar {
    border:none!important
}
.payment_method_box .payment_add_card {
    margin-bottom:0!important
}
.input_icon_align .mat-form-field-flex {
    align-items:center
}
.notification_list .mat-tab-body-wrapper {
    margin-top:50px
}
.notification_list .mat-expansion-panel-header-title {
    border-left:5px solid var(--clBlua);
    padding:5px 0 5px 19px;
    display:flex;
    align-items:center;
    font-size:20px;
    font-weight:600;
    color:var(--clBlka);
    position:absolute;
    left:1px;
    top:4px
}
.subscription-billing .mat-slide-toggle-label {
    flex-direction:row-reverse!important;
    justify-content:space-between
}
.subscription-billing .mat-slide-toggle-content {
    font-size:15px;
    font-display:swap;
    font-family:'Clash Display';
    font-weight:500;
    color:#818181;
    margin:0 20px
}
.red_strip .custom_toggle .mat-slide-toggle-content {
    color:#fff!important
}
.insurance_step_con .mat-form-field-flex {
    display:inline-flex;
    align-items:center
}
.subscription-billing .cus_matTab .mat-tab-label {
    height:56px
}
.subscription-billing .cus_matTab .mat-tab-header {
    padding:0
}
.prifix_txt_align mat-form-field .mat-form-field-flex {
    align-items:center
}
.sign_in_con .ic_password .mat-form-field-flex {
    align-items:center
}
.Subscription_plan_box .mat-radio-outer-circle {
    border-color:#818181
}
.crt_Ship_body .p_reletive {
    position:relative
}
.crt_Ship_body .back_btn {
    position:absolute;
    left:1%;
    border-radius:10px;
    bottom:23px;
    height:50px;
    width:180px;
    background:#818181
}
.crt_Ship_body .accept_btn {
    border-radius:10px;
    height:50px;
    width:180px
}
.Subscription_plan_box .mat-radio-checked .mat-radio-label-content {
    color:#818181
}
.payment_method_box #card-info .text-center {
    display:block
}
@media screen and (max-width:676px) {
    .payment_method_box #card-info .row {
        flex-wrap:inherit;
        overflow:auto
    }
    .payment_method_box #card-info .row::-webkit-scrollbar {
        height:0
    }
    .payment_method_box #card-info .row .col-md-4 {
        width:70%
    }
    .payment_method_box #card-info .text-center {
        display:none
    }
    .payment_add_card {
        position:relative
    }
    .payment_add_card .payment_cardAdded {
        margin:0 0 46px!important
    }
    .payment_add_card .material-icons {
        font-size:22px!important
    }
    .payment_add_card .add_bill_info {
        position:absolute;
        right:10px;
        bottom: -15px;
        flex-direction:row!important;
        border:none!important;
        min-height:auto!important;
        background:0 0!important;
        margin:0!important
    }
    .add_bill_info p {
        font-size:16px!important;
        margin:0 0 0 4px
    }
}
.mob_responsive_subscription {
    background:#fff!important
}
.mob_responsive_subscription .main-panel {
    background:#fff!important
}
.er_mess_flag_drop {
    position:absolute;
    width:161px;
    bottom:-14px
}
.edit_location_inputes .mat-form-field-subscript-wrapper {
    left:-72px
}
.c_flag_img {
    width:auto;
    height:18px
}
.add_location_inputes .mat-form-field-infix:first-child {
    width:100%!important
}
.edit_location_inputes .mat-form-field-infix:first-child {
    width:100%!important
}
.bdr_l_0 .custom_input {
    border-top-left-radius:0!important;
    border-bottom-left-radius:0!important
}
.num_country_code {
    display:flex;
    align-items:center
}
.num_country_code mat-form-field:first-child {
    width:85px
}
.num_country_code mat-form-field:last-child {
    width:calc(100% - 85px)
}
.shipper_ship_create .mat-form-field-infix {
    width:100%!important
}
.shipper_ship_create .country_code_fld mat-select {
    margin-top:-1px
}
.order_summary_b .mat-radio-label-content {
    color:#818181;
    font-size:18px
}
.mat-radio-checked .mat-radio-label-content { color: #000 !important;}
.num_country_code .mat-form-field-subscript-wrapper {
    position:absolute;
    left:-83px
}
#ship_car_tabs.cus_matTab .mat-tab-label {
    height:48px!important
}
#ship_car_tabs.cus_matTab .mat-tab-label:first-child {
    padding-left:0
}
#ship_car_tabs .mat-tab-header {
    padding-left:0
}
#ship_car_tabs .location_added_card_con.col-md-3 {
    width:25%!important;
    margin-left:0
}
.create_lanes_p .mat-tab-header {
    border-bottom:none
}
.create_lanes_p .c_lanes_sub_tabs .mat-tab-label-active {
    font-size:17px;
    background:#3787e6!important;
    opacity:1;
    border-radius:40px;
    color:#fff!important
}
.create_lanes_p .c_lanes_sub_tabs .mat-focus-indicator {
    font-size:17px;
    background:#fff;
    opacity:1;
    border-radius:40px;
    color:#fff;
    color:#000
}
.create_lanes_p .c_lanes_sub_tabs .mat-ink-bar {
    display:none
}
.create_lanes_p .c_lanes_sub_tabs .mat-tab-labels {
    justify-content:space-between;
    width:38%;
    margin-bottom:50px
}
.create_lanes_p .parent_tabs .mat-tab-labels {
    display:flex;
    margin-top:30px
}
.create_lanes_p .mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color:#3787e6!important
}
.create_lanes_p .mat-form-field-infix {
    padding:0
}
.create_lanes_p .dropdown-toggle::after {
    display:none
}
.create_lanes_p .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background:#3787e6!important
}
.create_lanes_p .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:#3787e6
}
.create_lanes_p .mat-radio-button.mat-accent .mat-radio-inner-circle,.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color:#3787e6
}
.create_lanes_p .mat-tab-label-container {
    z-index:0
}
.create_lanes_p .quote_bids_tabs .mat-tab-labels {
    display:flex;
    margin-top:-21px!important
}
.quote_bids_tabs .mat-tab-header {
    padding:0
}
#booking_tab .mat-tab-header {
    max-width:400px
}
.create_lanes_p .Empty_State_body {
    margin:40px 0 0 0
}
.create_lanes_p .mt-4 .selected_filter_con {
    margin-top:20px
}
.lane_filtter .selected_filter_con .label_txt {
    width:250px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis
}
.mat-active .mat-option-text {
    color:#3787e6!important
}
.side_panel_manage .fixed-top {
    z-index:0!important
}
.booking_lanes {
    overflow-x:hidden
}
.booking_lanes .booking_lane_container .mat-tab-body-wrapper {
    overflow:visible
}
.booking_lanes .booking_lane_container .mat-tab-body-wrapper {
    overflow:visible
}
.booking_lanes .booking_lane_container .mat-tab-body.mat-tab-body-active {
    overflow:visible
}
.booking_lanes .booking_lane_container .mat-tab-body-content {
    overflow:visible
}
.booking_lanes .booking_lane_container .filter_lanes_input_bx {
    position:relative;
    box-shadow:none
}
.booking_lanes .booking_lane_container .filter_lanes_input_bx:before {
    position:absolute;
    content:'';
    width:200vw;
    height:100%;
    top:0;
    background:#fff;
    left:-50vw;
    z-index:-1;
    box-shadow:0 8px 16px 0 #041a3433
}
.view_bids_tabs mat-ink-bar {
    height:5px!important
}
.mat-active .mat-option-text {
    color:#3787e6!important
}
.user_pro_banner .carrierN_img img {
    height: 60px !important;
    width: 60px !important;
    max-width: 60px;
    max-height: 60px;
    border-radius:50%
}
.user_pro_banner {
    margin-top:30px!important
}
.mat_dropdown.mat-option.mat-selected:not(.mat-option-multiple) {
    background:rgba(0,0,0,.12)
}
.booking_lane_container.cus_matTab .mat-tab-label-content {
    font-size:17px
}
.shipment_bidding .side-show-panel {
    position:fixed;
    background:#000000bf;
    width:100%;
    height:100vh;
    right:0;
    z-index:12;
    top:0;
    display:block;
    overflow:hidden
}
.dri_rtg_list .rvw_rtg_viw_edt {
    display:flex;
    align-items:center;
    margin:-6px 0 6px
}
.dri_rtg_list .rvw_rtg_viw_edt .rating_box {
    margin-right:8px
}
.dri_rtg_list .rvw_rtg_viw_edt .rating_box .rating_stars .material-icons {
    font-size:18px;
    line-height:20px
}
.confirm_lane_empty .Empty_State_body {
    margin:0
}
.confirm_lane_filtter .selected_filter_con {
    margin-top:22px!important
}
#driver_rating .rating_stars .material-icons {
    font-size:20px!important
}
#driver_rating .total_reviews {
    text-align:center
}
#rating_review_popup_one .rating_one .material-icons {
    font-size:20px
}
#qute_bid_container .mat-form-field-infix {
    padding:0!important
}
.progress_input_box .mat-slider-horizontal {
    height:40px!important;
    min-width:315px!important;
    padding:0
}
.progress_input_box .mat-slider-track-fill {
    background-color:#3787e6!important
}
.progress_input_box .mat-slider-thumb {
    background-color:#f0f0f0!important;
    border:none;
    width:32px!important;
    height:32px!important
}
.progress_input_box .mat-slider-horizontal .mat-slider-track-background {
    height:15px!important
}
.progress_input_box .mat-slider-horizontal .mat-slider-track-fill {
    height:15px!important
}
.progress_input_box .mat-slider-horizontal .mat-slider-track-wrapper {
    border-radius:40px;
    height:15px
}
.bid_assistant_flow .input_box .mat-form-field-wrapper {
    padding-bottom:0!important
}
.bid_assistant_flow .custum_input_css .mat-input-element {
    background:#fff;
    padding:0
}
.bid_assistant_flow .mat-slider-thumb {
    bottom:-23px
}
.gbw_box .box3 .mat-form-field-wrapper {
    padding-bottom:0
}
.gbw_box .box3 .mat-form-field-infix {
    padding:0;
    margin:-10px 5px 0 5px!important
}
.assits_popup .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display:none
}
.assits_popup .mat-ink-bar {
    height:0!important
}
.assits_popup .mat-tab-header {
    border-bottom:none!important
}
.assits_popup .mat-tab-label .mat-tab-label-content {
    display:inline-block;
    white-space:normal;
    line-height:15px
}
.assits_popup .mat-tab-label {
    max-width:170px
}
.assits_popup .mat-tab-label-active {
    background:#3787e6;
    opacity:100%;
    border-radius:10px;
    color:#fff
}
.assits_popup .mat-slider-track-fill {
    background-color:#3787e6!important
}
.assits_popup .mat-slider-thumb {
    background-color:#f0f0f0!important;
    border:none;
    width:26px!important;
    height:26px!important;
    right:-15px;
    bottom:-17px!important
}
.assits_popup .mat-slider-horizontal {
    min-width:200px
}
.assits_popup .mat-slider-horizontal .mat-slider-track-background {
    height:10px!important
}
.assits_popup .mat-slider-horizontal .mat-slider-track-fill {
    height:10px!important
}
.assits_popup .mat-slider-horizontal .mat-slider-track-wrapper {
    border-radius:40px;
    height:10px
}
.bid_assistant_flow .mat-expansion-panel .mat-expansion-panel-content {
    background:#fff
}
.bid_assistant_flow .mat-expansion-panel .mat-expansion-panel-body {
    padding:25px 43px
}
.bid_assistant_flow .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
    height:60px
}
.bid_assist_popup .mat-expansion-panel .mat-expansion-panel-content {
    background:#fff
}
.bid_assist_popup .mat-expansion-panel .mat-expansion-panel-body {
    padding:25px 20px
}
.bid_assist_popup .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
    height:60px
}
.assits_popup .mat-slider-horizontal {
    min-width:143px
}
#bid_qoute_page .mat-expansion-panel .bid_qoute_page {
    max-width:85%!important
}
#bid_qoute_page .mat-expansion-panel input {
    max-width:40px;
    height:15px;
    text-align:center
}
#bid_qoute_page .mat-expansion-panel.mat-expanded {
    margin:0 -22px!important
}
#bid_qoute_page .mat-expansion-panel {
    margin-bottom:20px!important
}
#bid_qoute_page .mat-expansion-panel:last-child {
    margin-bottom:0!important
}
#bid_qoute_page .mat-expansion-panel {
    margin:0 -22px;
    border-radius:0;
    background:#f0f0f0
}
#bid_qoute_page .mat-expansion-panel-header-title {
    max-width:294px
}
#bid_qoute_page .mat-expansion-panel-header-description {
    color:#3787e6;
    font-size:20px;
    font-style:normal;
    font-weight:600;
    line-height:normal
}
#bid_qoute_page .mat-expansion-panel .mat-expansion-panel-content {
    background:#fff!important
}
#bid_qoute_page .progress_input_box {
    margin:0 -34px
}
.bid_assist_popup .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:#3787e6
}
.bid_assist_popup .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color:#3787e6
}
#qoute_bid_popup .mat-expansion-panel .qoute_bid_popup {
    max-width:85%!important
}
#qoute_bid_popup .mat-expansion-panel input {
    text-align:right;
    padding:0;
    font-weight:600;
    padding-right:5px!important
}
#qoute_bid_popup .mat-expansion-panel-body .col-md-6 {
    margin-bottom:20px
}
#qoute_bid_popup .mat-expansion-panel.mat-expanded {
    margin:0 -22px!important
}
#qoute_bid_popup .mat-expansion-panel {
    margin-bottom:20px!important
}
#qoute_bid_popup .mat-expansion-panel {
    margin:0 -22px;
    border-radius:0;
    background:#f0f0f0
}
#qoute_bid_popup .mat-expansion-panel-header-title {
    max-width:294px
}
#qoute_bid_popup .mat-expansion-panel-header-description {
    color:#3787e6;
    font-size:20px;
    font-style:normal;
    font-weight:600;
    line-height:normal
}
#qoute_bid_popup .mat-expansion-panel .mat-expansion-panel-content {
    background:#fff!important
}
#qoute_bid_popup .progress_input_box {
    margin:0 -34px
}
.vv_first_time_popup .mat-expansion-panel-body {
    padding:15px 24px;
    background:#fff
}
.progress_lable input {
    width:55px;
    max-width:65px
}
.bid_assistant_flow .progress_lable input {
    width:64px;
    max-width:65px;
    text-align:right
}
.progress_lable input:focus {
    background:#dfe1e6!important;
    text-align:center;
    border-radius:5px
}
.bid_assistant_flow .progress_lable input:focus {
    max-width:60px;
    height:25px;
    text-align:left
}
#qoute_bid_popup .progress_lable input:focus {
    height:25px!important;
    margin:11.5px 0;
    text-align:left
}
#qoute_bid_popup .progress_lable input {
    background:0 0
}
.vv_first_time_popup {
    max-height:calc(100vh - 119px)!important
}
.vv_first_time_popup .assits_popup .mat-slider-thumb {
    right:-10px
}
.vv_first_time_popup .progress_lable .fs_16 {
    font-size:12px!important
}
.edit_popup_comodity .col-md-3:nth-child(5) .mat-input-element {
    padding:10px!important
}
.fixed-top {
    z-index:5!important
}
#shipment_card_Q .dropdown-menu .dropdown-item {
    min-height:22px
}
#shipment_card_Q .loca_iconFlag .picup_blue {
    width:11px
}
.create_lanes_p .list_option {
    font-size:30px!important
}
.list_option::before {
    color:#818181;
    right:-7px;
    top:-5px;
    position:absolute
}
.gbw_box .mat-radio-inner-circle {
    background-color:#3787e6!important
}
.mat-radio-outer-circle {
    border-color:#3787e6!important
}
.vv_first_time_popup .progress_input_box .mat-slider-horizontal {
    height:40px!important;
    min-width:311px!important
}
.vv_first_time_popup .custum_input_css .mat-form-field-wrapper {
    padding-bottom:10px
}
.vv_first_time_popup #qoute_bid_popup .mat-expansion-panel-header-description {
    font-size:16px
}
.progress_input_box .mat-expansion-indicator::after {
    padding:6px
}
.bid_assistant_flow .progress_lable input:focus {
    max-width:70px!important;
    height:25px!important;
    margin:11.5px 0
}
.bid_assistant_flow .input_box .custum_input_css .mat-input-element {
    text-align:left
}
.custum_input_css .mat-accordion .mat-expanded:last-child {
    box-shadow:none
}
.update_bid_quote .mat-slider-horizontal .mat-slider-wrapper {
    top:0
}
.update_bid_quote .mat-form-field-subscript-wrapper {
    top:50px
}
.update_bid_quote .mat-expansion-panel-header-description {
    max-width:100px
}
.update_bid_quote .mat-expansion-panel-header-title {
    max-width:234px!important
}
.update_bid_quote .custum_input_css .mat-form-field-wrapper {
    padding-bottom:5px
}
.update_bid_quote .progress_input_box .mat-slider-horizontal {
    height:10px!important
}
.update_bid_quote .p_absulute_45 {
    right:55px!important
}
#shipment_card_ratting .rating_stars .material-icons {
    font-size:16px
}
#shipment_card_ratting .total_reviews {
    font-size:9px;
    text-align:center
}
@media screen and (max-width:1023px) {
    app-shipment-card app-rating-view {
        margin:0
    }
    #shipment_card_ratting .rating_stars .material-icons {
        font-size:14px
    }
}
@media screen and (max-width:676px) {
  #topnav {  position: relative }
  .tutorial_wight_box .mob_view .vertical_scrollBar::-webkit-scrollbar {  width: 1px }
  .tutorial_wight_box .row .vertical_scrollBar:after { border: none !important;}
}

.guide_nav_card::-webkit-scrollbar { width: 0px; height: 0px }

    #topnav {
        position:relative
    }
    .tutorial_wight_box .mob_view .vertical_scrollBar::-webkit-scrollbar {
        width:1px
    }

.guide_nav_card::-webkit-scrollbar {
    width:0px;
    height:0px
}
@media screen and (max-width:1023px) {
    .fixed-top {
        position:relative
    }
    #topnav .container {
        max-width:88%
    }
    .custom_menu ul li a {
        font-size:8px!important;
        line-height:12px!important;
        padding:25px 8px!important
    }
    .logo-img {
        min-width:100px!important;
        max-width:104px!important
    }
}
@media screen and (min-width:1024px) and (max-width:1300px) {
    .fixed-top {
        position:relative
    }
    #topnav .container {
        max-width:88%
    }
    .custom_menu ul li a {
        font-size:8px!important;
        line-height:12px!important;
        padding:25px 8px!important
    }
    .logo-img {
        min-width:100px!important;
        max-width:104px!important
    }
}
.dark_mob_view {
    overflow:hidden
}
app-shipment-view-top-section .shipment_detailPage .btn_crt .bt {
    background:#3787e6!important
}


.tutorial_wight_box .vertical_scrollBar::-webkit-scrollbar { width: 0px;}
.tutorial_wight_box .row .vertical_scrollBar:after { content: ''; width: 0; height: 100%; position: absolute; border: 2.5px solid #ECECECCC; top: 0; left: 0; right: 0px; }


/* shipper subscription css */
.pct_c  .mat-radio-outer-circle  { border-color: #D6D6D6 !important; border-width: 1px !important; }
.pct_c  .mat-radio-inner-circle { left: -3px !important; top: -3px !important; height: 26px !important; width: 26px !important; }
/* .raddio_btn_carrier .mat-radio-inner-circle { left: -3.2px !important; } */
/* .pct_c .mat-radio-button { width: 170px;} */

.mat-radio-outer-circle  { border-color: #D6D6D6 !important; border-width: 1px !important; }
/* .mat-radio-inner-circle { left: -3px !important; top: -3px !important; height: 26px !important; width: 26px !important; } */
.mat-radio-inner-circle { background-color: #3787e6 !important;}
.view_snapshot_btn button { border: none; background: none; text-decoration: underline;}

.e_carrier_popup .mat-tooltip { background: #000 !important; }

.unlock_btn button { background: #7A7A7A !important; }

.promo_app_input .mat-form-field-infix { border-top: 0px; display: flex; align-items: center;} 
.promo_app_input .mat-form-field-wrapper  { padding-bottom: 0px;}
.carrier_side_input .mat-form-field-infix {  padding: 10px 0;} 
.carrier_side_input .mat-form-field-wrapper  { display: flex;}



@media screen and (max-width:676px) {

    .carrier_side_input .mat-form-field-infix { padding: 7px 0px;}
    
}